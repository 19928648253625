.order-tracking {
    .mat-step-icon {
        height: 40px;
        width: 40px;
        .mat-icon {
            --icon-size: 24px;
            font-size: var(--icon-size);
            height: var(--icon-size);
            width: var(--icon-size);

            svg path {
                fill: white;
                stroke: white;
            }
        }
    }

    .mat-step:last-child {
        .mat-vertical-content-container {
            display: none;
        }
    }

    .mat-stepper-vertical-line::before {
        border-left-color: #21bf73;
    }

    .mat-vertical-content-container {
        margin-left: 20px;
        padding-top: 14px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
        padding: 18px 0px;
    }

    .mat-step-header .mat-step-icon,
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: #21bf73;
        color: white;
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled="false"] {
        background-color: transparent;
        cursor: default;
    }
}
