@use "abstracts/variables" as *;
@use "abstracts/dimensions" as *;
@use "mixin/breakpoint" as *;

.update-profile {
    .mat-icon[svgicon] {
        svg {
            path {
                fill: white;
            }
        }
    }
}

.sl-list {
    .mat-list-item {
        .mat-icon[svgicon="user-edit"],
        .mat-icon[svgicon="user-x"] {
            svg {
                path {
                    fill: none !important;
                }
            }
        }

        &.active {
            .mat-icon[svgicon="user-edit"],
            .mat-icon[svgicon="user-x"] {
                svg {
                    path {
                        stroke: #006879;
                    }
                }
            }
        }
    }
}

.correct-username-tooltip {
    white-space: pre;
    font-size: 12px !important;
}

.forgot-password,
.txt-resend {
    cursor: pointer;
    text-decoration: underline;
}

.delete-own-account {
    .profile-image {
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid rgba(74, 98, 104, 0.1);
    }
}

/** Block user **/

.block-user-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
    height: 700px;
    padding: 18px;
    overflow-y: auto;
    scrollbar-width: none; // For Firefox
    -ms-overflow-style: none; // For Edge
    // For Webkit browsers (Chrome, Safari)
    &::-webkit-scrollbar {
    display: none;
    }
}

.user-list-container {
    width: 100%;
    display: grid;
    grid-gap: 18px;
    gap: 18px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.user-list {
    max-width: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: $default-card-radius;
    padding: 10px;
    position: relative;
    gap: 10px;
    user-select: none;
    overflow: hidden;
    // min-height: 98px;
    cursor: pointer;

    .profile {
        display: flex;
        gap: 8px;

        .img-box {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;

            .img {
                width: 100%;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .detail-box {
            height: auto;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            overflow: hidden;

            .name {
                max-width: 150px;
                font-size: 14px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .description {
                margin: 0;
                font-size: 12px;
                white-space: nowrap;
            }

            .type {
                font-size: 12px;
                color: $color-gray;
                margin: 0;
                white-space: nowrap;
            }
        }
    }

    // .action-btn {
    //     border-radius: $default-card-radius;
    //     border: 1px solid $color-danger;
    //     width: fit-content;
    //     height: fit-content;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     height: 40px;
    //     min-width: 40px;
    //     max-width: 40px;
    //     cursor: pointer;
    // }
}

.empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .empty .image {
        width: 300px;
    }
}

/** Small Screen **/
@include media-breakpoint-down(sm) {
    .user-list-container {
        grid-template-columns: 1fr;
    }

    .user-list {
        width: 100% !important;
        .btn-group .btn-md .txt {
            display: none;
        }

        .profile .img-box {
            width: 60px;
            height: 60px;
        }
    }

    .user-list .profile .detail-box .name {
        max-width: 100px;
        // font-size: 14px;
        // margin: 0;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
}

/** Medium Screen **/
@include media-breakpoint-down(md) {
    .user-list {
        width: 100% !important;
        .btn-group .btn-md .txt {
            display: none;
        }
    }
}

/** Medium Screen **/
@include media-breakpoint-between(lg, md) {
    .user-list-container {
        width: 100% !important;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }

    .user-list {
        // .btn-group .btn-md .txt {
        //     display: none;
        // }
        width: 100% !important;
        .profile .img-box {
            width: 60px;
            height: 60px;
        }
    }
}
