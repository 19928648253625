@use "abstracts/variables" as *;
@use "base/font" as *;

mat-divider.mat-divider {
  &.order-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.suggestion-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.divider-1 {
    border-top: 1px solid rgba(151, 151, 151, 0.1);
  }

  &.divider-2 {
    border-top: 1px solid rgba(74, 98, 104, 0.05);
  }

  &.divider-3 {
    // background: rgba(74, 98, 104, 0.1);
    border-top: 1px solid rgba(74, 98, 104, 0.1);
  }

  &.divider-4 {
    // align-self: stretch;
    // border-radius: 1000px;
    border-top: 1px solid rgba(217, 217, 217, 0.25);
  }

  &.width-100 {
    width: 100%;
  }

  // &.divider-5 {
  //   border-top: 1px solid rgba(0, 0, 0, 0.05);
  // }
}

div.divider {
  height: 1px;
  width: 100%;
  &.divider-1 {
    background: rgba(151, 151, 151, 0.1);
  }

  &.divider-2 {
    background: rgba(74, 98, 104, 0.05);
  }

  &.divider-3 {
    background: rgba(74, 98, 104, 0.1);
    border-top: 1px solid rgba(74, 98, 104, 0.1);
  }

  &.divider-4 {
    align-self: stretch;
    border-radius: 1000px;
    background: rgba(217, 217, 217, 0.25);
  }

  &.divider-5 {
    background: rgba(0, 0, 0, 0.05);
  }
}

div.divider-horz {
  height: 40px;
  width: 1px;
  background: rgba(74, 98, 104, 0.05);
}

.divider-vert {
  height: 100%;
  .divider-vert-1 {
    width: 1px;
    background: rgba(0, 0, 0, 0.5);
  }
}
