.camdigikey-success {
  mat-spinner.mat-spinner {
    width: 60px !important;
    height: 60px !important;

    svg {
      width: 60px !important;
      height: 60px !important;
    }
  }
}