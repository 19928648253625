@use "sass:math";

$columns: 12;
.form-group {
  margin-bottom: 0.5rem;
  padding: 0px 20px;
  &.no-padding {
    padding: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }
  &:last-child {
    padding-bottom: 1em;
  }
}

// .row {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   place-items: center;
//   margin-right: -15px;
//   margin-left: -15px;
// }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  align-self: center;
}
/*
@media (min-width: 1000px) {
  .col-sm-2 {
    flex: 0 0 percentage(math.div(2, $columns));
    max-width: percentage(math.div(2, $columns));
  }

  .col-sm-3 {
    flex: 0 0 percentage(math.div(3, $columns));
    max-width: percentage(math.div(3, $columns));
  }

  .col-sm-4 {
    flex: 0 0 percentage(math.div(4, $columns));
    max-width: percentage(math.div(4, $columns));
  }

  .col-sm-6 {
    flex: 0 0 percentage(math.div(6, $columns));
    max-width: percentage(math.div(6, $columns));
  }

  .col-sm-8 {
    flex: 0 0 percentage(math.div(8, $columns));
    max-width: percentage(math.div(8, $columns));
  }

  .col-sm-9 {
    flex: 0 0 percentage(math.div(9, $columns));
    max-width: percentage(math.div(9, $columns));
  }

  .col-sm-10 {
    flex: 0 0 percentage(math.div(10, $columns));
    max-width: percentage(math.div(10, $columns));
  }

  .col-sm-12 {
    flex: 0 0 percentage(math.div(12, $columns));
    max-width: percentage(math.div(12, $columns));
  }
}
*/

/*.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-6,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1;
}
*/

.form-control {
  display: block;
  width: 100%;
}
