@use "abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;

.mat-list-base.mat-nav-list,
.mat-list.mat-list-base {
  padding-top: 0;

  .mat-list-item {
    border-radius: 10px;
    color: #ffffff;

    &.ba-account-l-item {
      .mat-list-item-content {
        .mat-list-text {
          color: #ffffff;
          padding-left: 10px;

          :nth-child(1) {
            font-weight: $fw-semi-bold;
            font-size: $fs-normal;
          }

          :nth-child(2) {
            font-size: $fs-normal;
            color: #ffffff;
            &.account-name {
              color: #ffffff;
            }
          }
        }
      }
    }

    .mat-list-item-content {
      padding: 0 15px;

      .title {
        margin: 0;
        color: #ffffff;
        white-space: break-spaces;
      }

      mat-icon.mat-icon {
        margin-right: 10px;
        min-width: 24px;

        path {
          fill: #ffffff;
        }

        &.svg-path {
          &.svg-stroke {
            path {
              fill: none;
              stroke: #ffffff;
            }
          }
        }
      }
    }

    &.active {
      color: #ffffff !important;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.25);
      // mat-icon.mat-icon {
      //   path {
      //     fill: #ffffff !important;
      //   }

      //   &.svg-path {
      //     &.svg-stroke {
      //       path {
      //         fill: none;
      //         stroke: #ffffff !important;
      //       }
      //     }
      //   }
      // }

      .title {
        color: #ffffff;
      }
    }

    &.page-active {
      border: 1px solid #ffffff;
      color: #ffffff;

      mat-icon.mat-icon {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.radio-group-01 {
  display: flex;
  flex-direction: column;

  .general-link,
  .custom-radio-button {
    margin: 4px;
  }
}

.radio-group-title-01 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  button {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}
