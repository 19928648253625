@use "abstracts/variables" as *;
@use "base/font" as *;

.option-menu.mat-menu-panel {
  max-width: 500px;
  border-radius: 25px;
  // width: 296px;
  min-width: 120px;
  padding-top: 10px;
  padding-bottom: 10px;

  .polygon-img {
    position: absolute;
    top: 0px;
    right: 15px;
  }

  .polygon-img-1 {
    position: absolute;
    top: 0px;
    right: 8px;
  }

  .polygon-img-2 {
    position: absolute;
    top: 0px;
    left: 80px;
  }

  &.mat-menu-after {
    .polygon-img {
      position: absolute;
      top: 0px;
      left: 15px;
    }
  }

  &.custom-2 {
    width: 230px;
    padding: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  }

  &.custom-3 {
    display: flex;
    width: 482px;
    padding: 20px;

    background: #fff;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .mat-menu-content {
      padding: 0;
    }
  }
}

.option-menu {
  padding: 12px 20px;
  margin-top: 15px;

  .mat-menu-content {
    .mat-menu-item {
      color: $color-gray;
      font-size: $fs-normal;
      min-width: 200px;
      border-radius: 15px;
      line-height: 45px;
      height: 45px;

      mat-icon.mat-icon {
        color: $color-secondary;
        margin-right: 15px;

        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $color-secondary;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $color-secondary;
            }
          }

          &.svg-stroke {
            path {
              stroke: $color-secondary;
            }
          }
        }
      }

      &:hover {
        background: rgba(0, 104, 121, 0.05);
      }
    }
  }

  // &.opt-card-actions {
  //   .mat-menu-content {
  //     .mat-menu-item {
  //       color: $color-secondary;
  //     }
  //   }
  // }

  &.custom-2 {
    .mat-menu-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .mat-menu-item {
        color: $color-gray;
        font-size: $fs-normal;
        min-width: 200px;
        border-radius: 12px;
        height: 40px;
        line-height: inherit;
      }
    }
  }

  &.buy-listing-detail-opt {
    width: 296px;
  }
}

.sort-by.mat-menu-panel {
  margin-top: 27px;
  border-radius: 25px;
  width: 256px;
  padding: 5px 25px;
}

.map-menu.mat-menu-panel {
  width: 209px;
  background: #ffffff;
  border: 1px solid rgba(74, 98, 104, 0.1);
  border-radius: 20px;
  padding: 10px 20px;
}
