@use "base/font" as *;
@use "base/colors" as *;
@use "abstracts/variables" as *;
@use "mixin/breakpoint" as *;

$mobile-image-height: 200px;

.content.app-picture {
  app-picture {
    picture {
      img {
        width: 100%;
        cursor: pointer;
        height: 246px;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
      }
    }
  }
}

.image-container.app-picture {
  // not 100% like previous code
  // related to height, when small screen the previous height 200px in each image
  app-picture {
    height: 100%;
    width: 100%;

    picture {
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // height: 200px;
      }
    }
  }
}

.background.app-picture-img-bg {
  app-picture {
    img {
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }
  }
}

.image.app-picture-img-inner {
  app-picture {
    img {
      height: 85%;
      width: auto;
      margin: auto;
      -webkit-filter: drop-shadow(5px 5px 10px #000000);
      filter: drop-shadow(5px 5px 10px #000000);
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

.app-picture-product-detail {
  div {
    app-picture {
      picture {
        img {
          width: 100%;
          height: 545px;
          object-fit: cover;
          // border-radius: 25px;
          cursor: pointer;
        }
      }

      &.custom-app-picture {
        picture {
          img {
            height: 267px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

/** Medium Breakpoint **/

@include media-breakpoint-down(md) {
  .content.app-picture app-picture picture img {
    height: $mobile-image-height;
  }

  .app-picture-product-detail div app-picture picture img {
    height: $mobile-image-height;
  }
}
