$image-size: 49px;

.review-and-rating {
    .accordion-product-card {
        margin-bottom: 8px;

        .expansion-card {
            background-color: var(--light-grey);
            border-radius: 12px !important;

            .mat-expansion-panel-header {
                height: max-content;
                padding: 8px 18px 8px 8px;

                .mat-expansion-panel-header-title {
                    align-items: center;
                }
            }

            .mat-expansion-panel-body {
                padding: 0 8px 8px;
            }

            .product-img {
                display: flex;
                align-items: center;
                margin-right: 8px;

                img {
                    width: $image-size;
                    height: $image-size;
                    min-width: $image-size;
                    min-height: $image-size;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8px;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: start;
            }
        }

        .comment-container {
            .comment-field {
                width: 100%;
                background-color: var(--white);
                border-radius: 10px;
                border: 1px solid rgba($color: #000000, $alpha: 0.05);
                padding: 8px;

                &:focus-visible {
                    outline: none;
                }
            }
        }

        .img-container {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            border-radius: 10px;
            padding: 5px 0;
            display: -moz-box;
            display: -webkit-box;
            display: -moz-inline-box;
            display: -webkit-inline-box;

            .img-inset {
                height: 100px;
                width: 100px;
                position: relative;

                &:not(:last-child) {
                    margin-right: 4px;
                }

                &.add-pic {
                    display: flex;
                    padding: 13px 7px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;

                    border-radius: 10px;
                    border: 2px dashed rgba(0, 54, 64, 0.10);
                    background: #F6F6F6;
                    cursor: pointer;

                    .inside-icon {
                        border-radius: 4px;
                        width: 32px;
                        height: 32px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    span.caption-sm {
                        height: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 170%;
                    }
                }

                .img {
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid rgba(74, 98, 104, 0.10);
                    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
                }

                .remove {
                    position: absolute;
                    bottom: 8px;
                    right: 8px;

                    button.mat-icon-button.icon-btn-remove {
                        width: 26px;
                        height: 26px;
                        line-height: normal;
                    }
                }
            }
        }
    }
}