@use "abstracts/variables" as *;
@use "./breakpoint" as *;

// Container mixins

@mixin make-container($gutter: $container-padding-x) {
    --#{$prefix}gutter-x: #{$gutter};
    --#{$prefix}gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
    padding-left: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
    margin-right: auto;
    margin-left: auto;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-container-classes {
    // Single container class with breakpoint max-widths
    .bs-container,
    // 100% wide container at all breakpoints
    .bs-container-fluid {
        @include make-container();
    }

    .bs-container-no-padding {
        padding-right: 0;
        padding-left: 0;
    }

    // Responsive containers that are 100% wide until a breakpoint
    @each $breakpoint, $container-max-width in $container-max-widths {
        .bs-container-#{$breakpoint} {
            @extend .bs-container-fluid;
            // @debug "log: #{$breakpoint}";
            // @if ($breakpoint == "md") {
            //     @debug "worked: #{$breakpoint}";
            //     padding-right: calc(var(--#{$prefix}gutter-x) * 0.2);
            //     padding-left: calc(var(--#{$prefix}gutter-x) * 0.2);
            // } @else if (($breakpoint == "lg") or ($breakpoint == "xl")) {
            //     @debug "worked: #{$breakpoint}";
            //     padding-right: calc(var(--#{$prefix}gutter-x) * 0.5);
            //     padding-left: calc(var(--#{$prefix}gutter-x) * 0.5);
            // }
        }

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            %responsive-container-#{$breakpoint} {
                max-width: $container-max-width;
            }

            // Extend each breakpoint which is smaller or equal to the current breakpoint
            $extend-breakpoint: true;

            @each $name, $width in $grid-breakpoints {
                @if ($extend-breakpoint) {
                    .bs-container#{breakpoint-infix($name, $grid-breakpoints)} {
                        @extend %responsive-container-#{$breakpoint};
                    }

                    // Once the current breakpoint is reached, stop extending
                    @if ($breakpoint == $name) {
                        $extend-breakpoint: false;
                    }
                }
            }
        }
    }
}
