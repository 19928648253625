@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    .image {
        max-width: 300px;
        min-width: 114px;
        @include media-breakpoint-down(sm) {
            width: auto !important;
        }
    }
    .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;

        .no-result {
            font-size: $fs-regular;
            color: $dark-blue;
            font-weight: $fw-bold;
        }

        .short-desc {
            color: $grey;
            font-weight: $fw-normal;
            text-align: center;
        }
    }
}
