@use 'abstracts/variables' as *;

.email-verification{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: #fff;

    .main-form {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        .back-button{
            display: flex;
            position: absolute;
            top: 37px;
            left: 30px;
            align-items: center;

            .back{
                color: #47464F;
                margin-right: 20px;
            }

            .logo{
                width: 45px;
                margin-right: 10px;
            }

            .title{
                font-size: 18px;
            }
        }

        .form-verification {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;

            .logo-form {
                min-width: 0px;
                margin-left: 50px;

                .logo {
                    z-index: 1;
                }
            }

            .content-form {
                width: 100%;
                display: flex;
                justify-content: center;

                // margin-right: 30px;

                .sub-content{
                    color: #47464F;
                    background-color: #fff;
                    padding: 40px 0;
                    border-radius: 10px;
                    border: 1px solid #E2E2E2;
                    width: 500px;
                    display: flex;
                    justify-content: center;

                    .container{
                        width: 380px;
                        .title-form {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 50px;
                        }

                        .mat-form-field{
                            line-height: 1.5;

                            .mat-form-field-prefix, .mat-form-field-suffix{
                                margin-right: 5px;
                            }
                        }

                        .mat-stroked-button{
                            width: 390px;
                            margin-bottom: 25px;
                            text-align: left;
                            display: block;

                            .mat-icon{
                                width: 32px;
                                height: 32px;
                                margin: 0 10px 0 30px;
                            }
                        }

                        .my-otp-input, .my-otp-input-invalid{
                            text-align: center;
                        }
                    }

                }
            }

            .image-form {
                height: 100%;
                .image-svg {
                    height: 100%;
                    img {
                        height: inherit;
                        margin-right: -1px;
                    }
                }
            }
        }
    }

    //* <-- Media Portrait --/>
    @media only screen and (max-width: 1005px) {

        .form-verification {
            justify-content: center !important;
            flex-direction: column !important;
            padding: 50px;

            .space-4 {
                height: 15px !important;
            }

            .logo-form {
                .logo {
                    .image-logo {
                        display: none;
                        img {
                            height: 100% !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }

            .content-form {
                width: fit-content !important;
                margin-right: 0px !important;
                margin: 0 20px !important;
                position: relative;
                z-index: 1;

                .sub-content{
                    width: unset !important;
                    max-width: 385px !important;

                    .container{
                        width: unset !important;
                    }
                }
            }
        }
    }

    //! <-- End of Media Portrait --/>
    //* <-- Media Portrait Max-Screen (iPhone5) --/>
    @media only screen and (max-width: 320px) {
        #login {
            #logo {
                .logo {
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}