@use "abstracts/variables" as *;
@use "abstracts/mixins" as *;
@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;

.main-layout {
  width: 100vw;
  // @include media-breakpoint-down(md) {
  //   width: 100vw;
  // }
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  

  .wrapper {
    height: calc(100% - 80px);
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .mat-sidenav-container {
      height: 100%;
      .mat-sidenav {
        width: $menu-width-v2;
        height: 100%;
        border: none;
        // @include media-breakpoint-down(md) {
        //   width: 300px;
        // }
      }

      .mat-sidenav-content {
        background-color: white;
        position: relative;
      }
    }

    .side-menu-wrapper {
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        align-items: flex-start;
      }
      overflow-y: scroll;
      scrollbar-width: none; // For Firefox
      -ms-overflow-style: none; // For Edge
      // For Webkit browsers (Chrome, Safari)
      &::-webkit-scrollbar {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
    }

    .content-wrapper {
      height: 100%;
      flex: 1;
      position: relative;
      overflow-y: auto;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
      // padding: 0px 20px;
      // width: 85%;
    }
  }
}

.home-container {
  border-radius: 15px;
  margin: 18px;
  height: calc(100% - 36px);

  .news-feed {
    display: flex;
    justify-content: center;
    gap: 20px;
    height: calc(100vh - 80px);
    height: 100%;
    width: 100%;
    min-height: 0;
    overflow-y: auto;

    .right,
    .left {
      position: sticky;
      position: -webkit-sticky;
      width: 26%;
      height: auto;
      top: 0;
      display: flex;
      flex-direction: column;
    }

    .left {
      .s-l-wrapper {
        width: 278px;
      }
    }

    .right {
      padding-right: 10px;
      overflow: auto;
      /* Handle */
      // &::-webkit-scrollbar-thumb {
      //   background: rgba($color: #8e8ba8, $alpha: 0.1);
      //   border-radius: 5px;
      // }
    }

    .content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: auto;

      .content-header {
        border-radius: 25px;
        background: #ffffff;
        padding: 20px;
        margin-bottom: 10px;

        form.post {
          margin-top: 21px;
          display: flex;
          align-items: center;

          .description {
            flex-grow: 1;
            margin-right: 50px;
          }

          .mat-form-field-appearance-legacy .mat-form-field-underline {
            background-color: $color-form-underline;
          }
        }

        .ch-content {
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

.rounded-wrapper {
  background-color: $default-background;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  height: auto;
  padding: 18px;
  @include media-breakpoint-down(md) {
    padding: 10px;
  }
  border-radius: 25px;
  height: 100%;
  overflow-y: auto;
}

.rounded-wrapper-home {
  // background-color: $light-grey-bg;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  height: auto;
  padding-left: 0px 20px;
  @include media-breakpoint-down(md) {
    padding: 10px;
  }
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  width:100%;
  @media (min-width: 1377px) {
    width: 760px;
    margin: 0 auto;
  }
}

.rounded-wrapper__position-sticky {
  background-color: $default-background;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;
  height: auto;
  height: 100%;
  overflow-y: auto;
  padding-top: 0 !important;

  .header {
    @include position-sticky();
    background-color: white;
    padding: 10px 18px;
    margin-top: 1px;

    .title-header {
      .report-title{
        color: $color-primary !important;
      }
    }
  }

  .position-sticky {
    position: sticky;
    top: 0;
    height: 0;
  }
}

.content-scroll {
  overflow-y: scroll;
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Edge
  // For Webkit browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 18px;
  // padding-right: 5px;
}

@media only screen and (max-width: 1100px) {
  .news-feed {
    .right,
    .left {
      display: none;
    }

    .content {
      width: 80%;
    }
  }
}
