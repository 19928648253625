@use 'abstracts/variables' as *;

.app-menu {

  .dash-row {
    display: block;
    width: 100%;
    overflow: auto;
  }

  .dash-row+.dash-row {
    margin: 0rem 1rem;
  }

  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .mat-dialog-container {
    background: #f8f9fe;
    padding: 50px 80px !important;

    .mat-dialog-actions {
      button.mat-focus-indicator.mat-button.mat-button-base.mat-primary {
        color: #59595b;
      }

      button {
        background: #fff;
        padding: 5px 25px;
      }
    }
  }

  // .mat-toolbar-row,
  // .mat-toolbar-single-row {
  //   min-height: $menu-height;
  // }

  // .mat-list-base .mat-list-item.mat-list-item-with-avatar,
  // .mat-list-base .mat-list-option.mat-list-item-with-avatar {
  //   height: 42px !important;
  //   display: flex;
  //   align-items: center;
  // }
}
