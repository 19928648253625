@use "base/font" as *;
@use "abstracts/variables" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;
@use "mixin/flex" as *;

$imageHeightSm: 200px;

.product-detail-page {
    .product-info-container {
        @include flexbox();
        @include flex-wrap(wrap);
    }

    .image.image-product-detail {
        width: var(--product-detail-image-width) !important;
        height: var(--product-detail-image-height) !important;

        .app-picture-product-detail div app-picture picture img {
            width: var(--product-detail-image-width);
            height: var(--product-detail-image-height);
        }

        .slider {
            .default-img-product {
                width: inherit;
                height: inherit;
            }
        }
    }

    .content-dialog {
        table {
            border-spacing: 0 6px;

            tr {
                text-align: left;

                th {
                    width: max-content;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .right-side {
        width: calc(100% - 400px - 16px);
        min-width: 250px;

        @media screen and (max-width: "1046px") {
            width: 100%;
            border-bottom: 1px solid rgba($color: #4a6268, $alpha: 0.05);
            margin-bottom: 8px;
        }
    }

    .tab-product-review {
        .card-rating {
            display: flex;
            height: 148px;

            .side-container {
                flex: 1;

                &.rating-star {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    place-content: center;
                    gap: 8px;
                }
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;

                .side-container {
                    &.rating-star {
                        margin-top: 20px;
                    }
                }
            }
        }

        .card-review {
            .review-header {
                .header-content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    place-content: center space-between;
                    align-items: center;
                    gap: 12px;

                    .action-left {
                        .review-toggle {
                            cursor: pointer;
                        }
                    }
                }
            }

            .review-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                .review-item {
                    width: 100%;
                }

                .no-review-container {
                    display: flex;
                    height: 347px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;

                    .no-rating-desc {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }
    }

    &.report-content {
        .product-info-container {
            flex-direction: column;
        }

        .image.image-product-detail {
            width: 100% !important;
        }
    }
}

.option-menu {
    .rate-filter-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        .menu-rating-content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .star-filter {
                display: flex;
                align-items: flex-start;
                gap: 8px;
                max-height: 43px;

                .star-item {
                    width: 63px;
                    display: flex;
                    align-items: flex-start;
                    gap: 4px;
                    cursor: pointer;
                    border-radius: 8px;
                    border: 1px solid #efefef;
                    background: #efefef;
                    color: #979797;
                    padding: 8px 16px;

                    &.all {
                        width: 87px;
                    }

                    &.active {
                        border: 1px solid $light-green;
                        background-color: #ffffff;

                        span {
                            color: $light-green;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.prod-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: 100%;
    // background-color: white;
    // padding: 18px;
    // border-radius: 10px;

    .action-top-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .act-content {
            display: flex;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            background-color: white;
            border-radius: 10px;

            .act-left {
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                mat-icon.mat-icon {
                    width: 24px;
                    height: 24px;
                    svg {
                        path {
                            fill: $color-primary;
                        }
                    }
                }

                color: $color-primary !important;
                font-size: $fs-regular;
                font-style: normal;
                font-weight: $fw-bold;
                line-height: normal;
            }

            .act-right {
                display: flex;
                align-items: center;
                gap: 16px;

                .btn-md-1 {
                    mat-icon {
                        svg {
                            path {
                                fill: white;
                            }
                        }
                    }
                }

                .btn-edit-2 {
                    display: flex;
                    padding: 10px 16px;
                    align-items: flex-end;
                    gap: 10px;
                    height: 45px;

                    border-radius: 10px;
                    background: #fda40d;
                    border: none;
                    cursor: pointer;

                    .inner-btn {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;

                        color: #fff;
                        font-size: $fs-normal;
                        font-style: normal;
                        font-weight: $fw-normal;
                        line-height: normal;

                        mat-icon.mat-icon {
                            width: 20.034px;
                            height: 20px;
                        }
                    }
                }

                button.act-more {
                    cursor: pointer;
                    display: flex;
                    height: 40px;
                    width: 40px;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    border: none;

                    border-radius: 8px;
                    background: rgba(0, 54, 64, 0.1);

                    mat-icon.mat-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    .prod-detail-content {
        height: 100%;
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 10px;
        overflow: hidden;
        gap: 18px;

        .content-left {
            width: 60%;
            height: 100%;
            position: relative;
            background-color: white;
            border-radius: 10px;

            .inside {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                overflow-y: auto;
                // padding-bottom: 15px;

                .cont-left-top {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                    padding: 18px 18px 0 18px;

                    .prod-img-wrap {
                        width: 390px;
                        height: 267px;
                        border-radius: 10px;

                        .slider {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            position: relative;
                            border-radius: 10px;

                            .default-img {
                                width: 100%;
                                height: 267px;
                                object-fit: cover;
                                border-radius: 25px;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                }
                            }
                        }

                        .p-img,
                        .default-img {
                            width: 100%;
                            height: 267px;
                            object-fit: cover;
                            border-radius: 25px;
                            cursor: pointer;
                        }

                        .slide {
                            position: absolute;
                        }

                        .arrow {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }

                        .arrow.prev {
                            left: 20px;
                            font-size: $fs-medium;

                            &:hover {
                                color: $light-grey-bg;
                            }
                        }

                        .arrow.next {
                            right: 20px;
                            font-size: $fs-medium;
                            text-align: right;

                            &:hover {
                                color: $light-grey-bg;
                            }
                        }

                        .nav {
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            width: 100%;
                            margin: auto;
                            text-align: center;
                        }

                        .dots {
                            padding-left: 0;
                        }

                        .nav .dot,
                        .nav .dot span {
                            display: inline-block;
                            cursor: pointer;
                        }

                        .dots .dot {
                            position: relative;
                            width: 12px;
                            height: 12px;
                        }

                        .dots .dot span {
                            position: absolute;
                            top: 2px;
                            left: 2px;
                            width: 6px;
                            height: 6px;
                            text-indent: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            background: $light-grey-bg;
                            border: 1px solid transparent;
                            outline: none;
                            box-shadow: none;
                            border-radius: 50%;
                        }

                        .dots .dot span.active {
                            background-color: $light-green;
                        }
                    }

                    .prod-desc {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;

                        .descwr {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px;
                            align-self: stretch;

                            .desc-top {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                align-self: stretch;

                                .des-title {
                                    color: #000;
                                    font-size: $fs-regular;
                                    font-style: normal;
                                    font-weight: $fw-bold;
                                    line-height: normal;
                                    height: 29px;
                                    display: inline-table;
                                }

                                .caption-md {
                                    height: 24px;
                                }
                            }

                            .desc-bottom {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 4px;

                                .db-item {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    align-self: stretch;

                                    img {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }

                        .price {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            mat-icon.mat-icon {
                                width: 24px;
                                height: 24px;
                            }

                            span {
                                color: $color-secondary;
                                font-size: $fs-regular;
                                font-style: normal;
                                font-weight: $fw-bold;
                                line-height: normal;
                            }
                        }
                    }
                }

                .cont-left-bottom {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                    flex: 1;

                    .content-tab-1 {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        border-radius: 10px;

                        .contact {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                            align-self: stretch;

                            .title {
                                align-self: stretch;
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 170%;
                            }

                            .contact-list {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 8px;
                                align-self: stretch;

                                .contact-item {
                                    display: flex;
                                    align-items: flex-end;
                                    gap: 8px;
                                    align-self: stretch;

                                    .label {
                                        display: flex;
                                        align-items: flex-end;
                                        gap: 4px;

                                        mat-icon.mat-icon {
                                            width: 24px;
                                            height: 24px;
                                        }

                                        .text {
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 170%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .divider.vert-divider {
            width: 1px;
            height: 100%;
            background: rgba(0, 0, 0, 0.05);
        }

        .content-right {
            width: 39%;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            flex: 1 0 0;
            height: 100%;
            position: relative;
            border-radius: 10px;

            .message-title {
                display: flex;
                align-items: center;
                gap: 4px;
                width: 100%;
                padding-right: 29px;

                span {
                    color: #000;
                    font-size: $fs-regular;
                    font-style: normal;
                    font-weight: $fw-bold;
                    line-height: 170%;
                }

                .num-contact {
                    display: flex;
                    height: 20px;
                    padding: 4px 4.5px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    border-radius: 18px;
                    border: 1px solid #fff;
                    background: #f00;
                    color: #fff;
                    font-size: $fs-small;
                    font-style: normal;
                    font-weight: $fw-normal;
                    line-height: 170%;
                }

                button.btn-recycle {
                    margin-left: auto;
                    display: flex;
                    padding: 8px;
                    align-items: center;
                    gap: 4px;

                    border-radius: 8px;
                    background: rgba(0, 54, 64, 0.1);
                    backdrop-filter: blur(5px);

                    color: $color-primary;
                    font-size: $fs-normal;
                    font-style: normal;
                    font-weight: $fw-normal;
                    line-height: 170%;
                    /* 23.8px */
                }
            }

            .message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                align-self: stretch;
                overflow-y: auto;
                flex: 1;
                padding-right: 24px;

                .product-request {
                    display: flex;
                    padding: 12px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    position: relative;

                    .message-status {
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        left: 6px;
                        top: 6px;
                        border-radius: 100%;
                        background-color: #f00;
                    }

                    .header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;

                        .msg-profile {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            overflow: hidden;

                            .img-wrap {
                                width: 32px;
                                height: 32px;
                                flex-shrink: 0;
                                border-radius: 32px;
                                border: 1px solid rgba(74, 98, 104, 0.1);

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 32px;
                                    object-fit: cover;
                                }
                            }

                            span {
                                color: #000;
                                font-size: $fs-normal;
                                font-style: normal;
                                font-weight: $fw-bold;
                                line-height: normal;
                            }
                        }

                        button {
                            display: flex;
                            padding: 4px 8px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 180%;
                            height: 33px;
                            flex-shrink: 0;
                        }
                    }

                    .center-list {
                        .list-item {
                            display: flex;
                            align-items: flex-start;
                            gap: 4px;
                            align-self: stretch;

                            .label {
                                height: 24px;
                            }

                            .value {
                                color: $color-secondary;
                                font-size: $fs-normal;
                                font-style: normal;
                                font-weight: $fw-bold;
                                line-height: 170%;
                            }
                        }
                    }

                    .footer {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 4px;
                        align-self: stretch;

                        span {
                            height: 24px;

                            &.question {
                                align-self: stretch;
                                color: #000;
                                font-size: $fs-normal;
                                font-style: normal;
                                font-weight: $fw-normal;
                                line-height: 170%;
                            }
                        }
                    }
                }

                .no-chat-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 24px;

                    img {
                        width: 173.17px;
                        height: 150px;
                        flex-shrink: 0;
                    }

                    .no-chat-desc {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 4px;
                        align-self: stretch;

                        div {
                            color: $color-secondary;
                            text-align: center;
                            font-size: $fs-regular;
                            font-style: normal;
                            font-weight: $fw-bold;
                            line-height: 170%;
                        }

                        span {
                            text-align: center;
                            font-style: normal;
                            font-weight: $fw-normal;
                            line-height: 170%;
                        }
                    }
                }
            }
        }
    }
}

/** Medium Screen **/

@include media-breakpoint-down(md) {
    .product-detail-page {
        --product-detail-image-width: 100%;
        --product-detail-image-height: 200px;

        .product-info-container {
            @include flex-direction(column);
        }

        .image.image-product-detail {
            width: var(--product-detail-image-width) !important;
            height: var(--product-detail-image-height) !important;
        }

        .app-picture-product-detail {
            width: 100%;
            div app-picture picture img {
                max-height: var(--product-detail-image-height);
                max-width: var(--product-detail-image-width);
            }

            .slider {
                .default-img-product {
                    width: inherit;
                    height: inherit;
                }
            }
        }
    }

    .tab-product-review {
        .card-rating {
            height: auto !important;
        }
    }

    .prod-detail-container {
        .prod-detail-content {
            overflow: auto;
            @include flex-direction(column);
            padding-right: 4px;

            .content-left {
                width: 100%;
                height: auto;

                .inside {
                    padding-right: 0;
                    .cont-left-top {
                        flex-direction: column;

                        .prod-img-wrap {
                            width: 100%;
                            height: $imageHeightSm;

                            .p-img {
                                height: $imageHeightSm;
                            }
                        }
                    }
                }
            }

            .content-right {
                width: 100%;
                padding: 10px 0;
            }
        }

        .action-top-wrap {
            .act-content {
                padding: 0;

                .act-left {
                    gap: 2px;
                }

                .act-right {
                    gap: 8px;

                    .btn-edit-2 {
                        align-items: center;
                        height: 40px;
                    }
                }
            }
        }
    }
}
