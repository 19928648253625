@use "abstracts/variables" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;
@use "base/font" as *;

.mat-list.mat-list-base {
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5px;

  .mat-list-item {
    height: 50px;
    color: $color-gray;
  }
}

app-separator {
  border: 1px solid white;
  margin-top: 20px;
  margin-bottom: 20px;
}

button.mat-list-item.btn-list-item {
  font-size: $fs-normal;
}

.item {
  display: flex;

  .announcement {
    display: flex;
  }

  .cicle {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    text-align: center;
    border: 2px solid $light-blue;
    margin-left: 64px;

    .number {
      margin-top: -3px;
    }
  }
}

.side-nav {
  height: 100%;
  width: 250px;
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 10px;
  }

  .containers {
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content-button {
      margin-bottom: 20px;
      width: 100%;

      .border {
        border: 1px solid rgba(74, 98, 104, 0.05);
        margin-bottom: 20px;
      }

      .profile-content {
        margin-bottom: 20px;
        border-radius: 15px;
        border: 1px solid $dark-blue;
        padding: 0px 20px;
        width: 100%;
        display: flex;
        height: 50px;

        .swap-img {
          display: flex;
          gap: 10px;
          align-items: center;
          cursor: pointer;
          position: relative;
          padding: 4px 0;

          h4 {
            margin: 0;
            color: $dark-blue;
          }

          .icon-swap {
            width: 40px;
            height: 40px;
            position: relative;

            .profile-images {
              position: absolute;
              top: 8px;
              right: -2px;
            }
          }
        }
      }

      .text {
        font-size: 13px;
      }

      .link {
        font-size: 13px;
        color: $light-blue;
      }
    }
  }
}

.badge {
  margin: 0;
  padding: 0 8px;
  background-color: #4b6268;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: 10px;
}

.menu-wrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  overflow-y: auto;

  .menu-title {
    color: white;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    app-store-mode-menu {
      width: 100%;
    }
  }

  /** Store Mode layout **/

  .content-button {
    width: 100%;
    // margin-bottom: 50px;

    .profile-content {
      margin-top: 20px;
      border-radius: 15px;
      padding: 0 20px;
      width: 100%;
      border: 1px solid white;
      display: flex;
      height: 50px;

      .swap-img {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;
        padding: 5px 0;

        h4 {
          margin: 0;
          color: white;
        }

        .icon-swap {
          width: 40px;
          height: 40px;
          position: relative;
          .profile-images {
            position: absolute;
            top: 8px;
            right: -2px;
          }
        }
      }
    }
  }
}
