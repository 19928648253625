@import "abstracts/variables";

.follower-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 10px;
}

.rounded {
  border-radius: 10px;
  width: 100%;
  height: auto;
  background-color: $default-background;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;

    mat-divider.mat-divider {
      width: 100%;
      margin-bottom: 0;
    }

    h1 {
      font-size: 18px;
      margin: 0;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 43px);
    flex: 1;
    position: relative;

    .scroller {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 4px;
    }

    .grid-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      gap: 10px;
    }
  }
}

.empty-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.no-data img {
  width: 441px;
  height: 336.08px;
}
