.ql-toolbar.ql-snow {
  border-radius: 5px 5px 0 0;
  padding: 12px 8px;
}
.ql-container {
  border-radius: 0px 0px 5px 5px;
  min-height: 150px;
  p {
    line-height: 1.7;
  }
}
.disabled-toolbar {
  .ql-toolbar {
    border: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;
    ::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
    }
  }
  .ql-container.ql-snow {
    border: 1px solid #eaeaea;
    ::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
    }
  }
}

quill-editor {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 99;

  .ql-container {
    // background-color: #7776801A;
    // border: 1px solid #1C1A1F1A;
    width: 100%;
    border-radius: 16px;
    padding: 8px 15px;
    outline: none;
    min-height: unset !important;
  }

  .ql-editor {
    padding: unset;
  }

  .ql-mention-list {
    background-color: white;
    list-style-type: none;
    transform: translateY(-2px);
    margin-top: 0;
    padding: 0px;
    z-index: 100;
  }

  .ql-mention-list .ql-mention-list-item {
    cursor: pointer;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    min-width: 200px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      object-fit: cover;
    }

    span {
      font-weight: bold;
    }
  }

  .ql-mention-list .ql-mention-list-item.selected {
    background-color: #fdd8ea;
  }

  span.mention {
    // border-radius: 9px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    padding: 2px 0px;
    cursor: pointer;
    font-weight: bold;

    a {
      text-decoration: none;
      color: unset;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  span.mention:hover {
    text-decoration: underline;
  }

  .ql-mention-denotation-char {
    display: none;
  }
}

quill-view {
  display: flex;
  position: relative;

  .ql-container {
    background-color: unset;
    border: unset;
    width: 100%;
    border-radius: unset;
    padding: 0px;
    margin-left: unset;
    outline: none;
    min-height: unset !important;
  }

  .ql-editor {
    padding: unset;
    color: black;
    font-size: 12px;
    p {
      margin: 0;
    }
  }

  .ql-mention-list {
    background-color: white;
    list-style-type: none;
    transform: translateY(-2px);
    margin-top: 0;
    padding: 0px;
    z-index: 100;
  }

  .ql-mention-list .ql-mention-list-item {
    cursor: pointer;
    padding: 10px 10px;
  }

  .ql-mention-list .ql-mention-list-item.selected {
    background-color: #fdd8ea;
  }

  span.mention {
    // border-radius: 9px;
    // background-color: #FDD8EA;
    // padding: 2px 5px;
    cursor: pointer;
    font-weight: bold;

    a {
      text-decoration: none;
      color: unset;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  span.mention:hover {
    text-decoration: underline;
  }

  a {
    cursor: pointer;
    font-weight: bold;
    text-decoration: none !important;
    color: unset;
  }

  a:hover {
    text-decoration: underline !important;
  }

  .ql-mention-denotation-char {
    display: none;
  }
}
