@use "abstracts/variables" as *;
@use "base/font" as *;
@use "mixin/breakpoint" as *;

mat-card.mat-card {
  padding: 20px;
  border-radius: 10px;
  border: 0;
  margin-bottom: 10px;

  .mat-card-header {
    .mat-card-header-text {
      margin: 0 8px;
    }
  }
}

mat-card.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: unset;
}

/* card style */
.card-1 {
  background-color: #ffffff;
  border-radius: 10px;
}

.card-1-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 10px 0px 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.card-title {
  font-size: $fs-regular;
  font-weight: $fw-bold;
  line-height: 29px;
}

.card-1-content {
  padding: 10px 20px;
}

/** Medium Screen **/

@include media-breakpoint-down(md) {
  mat-card.mat-card {
    padding: 12px;
  }
}
