@use 'abstracts/variables' as *;

.post-card{
    padding: 2em !important;
    border-radius: 20px;
    box-shadow: 0px 5px 10px -1px rgb(0 0 0 / 5%),
                0px 1px 1px 0px rgb(0 0 0 / 14%),
                0px 1px 3px 0px rgb(0 0 0 / 12%);

    .post-option{
        display: flex;
        justify-content: space-between;
        padding: 5px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;
        margin-bottom: 20px;

        .option{
            width: 100%;
            text-align: center;
            padding: 10px;
            margin: 10px 0;
            font-size: 14px;
            color: #777680;
            cursor: pointer;

            .mat-icon{
                display: block;
                margin: 0 auto;
                margin-bottom: 10px;
                width: 24px;
                height: 24px;
            }

            &.active{
                color: $color-primary;
            }

            &:not(:last-child){
                border-right: 1px;
                border-right-color: #E4E1EC;
                border-right-style: solid;
            }
        }

        .option:hover{
            background-color: #7776801a;
        }
    }

}

.post-normal{
    position: relative;

    .draft{
        position: absolute;
        right: 0;
    }

    .new-post{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        overflow-y: auto;
        min-height: 300px;
        max-height: 500px;

        .form-parent{
            width: 100%;
            overflow-x: hidden;
        }

        .custom-error{
            height: 1em;
            color: $color-danger;
            font-size: .8em;
            margin-bottom: 10px;
        }

        .mat-icon-button{
            width: 40px;
            height: 40px;

            .profile{
                border-radius: 50%;
                vertical-align: unset !important;
            }
        }

        .comment{
            background-color: #f0f2f5;
            width: 100%;
            border-radius: 20px;
            border: none;
            padding: 0 15px;
            margin-left: 10px;
        }

        .comment-input{
            // background-color: #7776801A;
            border: 1px solid #1C1A1F1A;
            width: 100%;
            border-radius: 16px;
            padding: 10px 15px;
            outline: none;
        }

        textarea{
            resize: none;
            overflow: hidden;
            line-height: 1.5;
            // max-height: 300px;
        }

        .photo-section{
            width: 100%;
            min-height: 250px;
            // border-radius: 16px;
            // border: 1px solid #F0F0F0;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            position: relative;

            .photo{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                .photo-placeholder{
                    display: flex;
                    align-items: center;
                    color: #8D8D8D !important;

                    .mat-icon{
                        margin-right: 5px;
                    }
                }
            }

            .img-container{
                width: 100%;
                overflow-x: auto;
                display: -moz-box;
                display: -webkit-box;
                display: -moz-inline-box;
                display: -webkit-inline-box;
                border-radius: 16px;
                padding: 5px;


                .img-inset{
                    height: 250px;
                    width: 250px;
                    padding: 5px;
                    position: relative;

                    &.m1{
                        height: 100%;
                        width: 100%;
                    }

                    &.m2{
                        width: 50%;
                    }

                    &.m3{
                        width: 33.3333%;
                    }

                    .img{
                        border-radius: 16px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .remove{
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        .mat-icon-button{
                            background-color: #00000042;
                            color: #fff !important;
                        }
                    }
                }

            }

            .edit-photo{
                position: absolute;
                top: 10px;
                left: 10px;

                // .mat-icon-button{
                //     color: #8D8D8D !important;
                // }

                .mat-flat-button{
                    border-radius: 16px;
                    background-color: #00000042 !important;
                    color: #fff !important;
                }
            }


        }
    }
}

.post-note{
    position: relative;

    .draft{
        position: absolute;
        right: 0;
    }

    .new-post{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        overflow-y: auto;
        min-height: 300px;
        max-height: 500px;

        .custom-error{
            height: 1em;
            color: $color-danger;
            font-size: .8em;
            margin-bottom: 10px;
        }

        .form-parent{
            width: 100%;
            overflow-x: hidden;
        }

        .photo-section {
            width: 100%;
            min-height: 250px;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            position: relative;

            .photo {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                .photo-placeholder {
                    display: flex;
                    align-items: center;
                    color: #8D8D8D !important;

                    .mat-icon {
                        margin-right: 5px;
                    }
                }
            }

            .img-container {
                width: 100%;
                overflow-x: auto;
                display: -moz-box;
                display: -webkit-box;
                display: -moz-inline-box;
                display: -webkit-inline-box;
                border-radius: 16px;
                padding: 5px;

                .img-inset {
                    height: 250px;
                    width: 250px;
                    padding: 5px;
                    position: relative;

                    &.m1 {
                        height: 100%;
                        width: 100%;
                    }

                    &.m2 {
                        width: 50%;
                    }

                    &.m3 {
                        width: 33.3333%;
                    }

                    .img {
                        border-radius: 16px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .remove {
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        .mat-icon-button {
                            background-color: #00000042;
                            color: #fff !important;
                        }
                    }
                }

            }

            .edit-photo {
                position: absolute;
                top: 10px;
                left: 10px;

                .mat-flat-button {
                    border-radius: 16px;
                    background-color: #00000042 !important;
                    color: #fff !important;
                }
            }
        }
    }
}

.post-buy-sale{
    .new-post{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        overflow-y: auto;
        min-height: 300px;
        max-height: 500px;

        .mat-icon-button{
            width: 40px;
            height: 40px;

            .profile{
                border-radius: 50%;
                vertical-align: unset !important;
            }
        }

        .form-parent{
            width: 100%;
            overflow-x: hidden;
        }

        .comment{
            background-color: #f0f2f5;
            width: 100%;
            border-radius: 20px;
            border: none;
            padding: 0 15px;
            margin-left: 10px;
        }

        .advance{
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $color-primary;
        }

        .input-button{
            .mat-icon{
                color: #777680;
            }
        }

        .photo-section{
            width: 100%;
            min-height: 250px;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            position: relative;

            .photo{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                .photo-placeholder{
                    display: flex;
                    align-items: center;
                    color: #8D8D8D !important;

                    .mat-icon{
                        margin-right: 5px;
                    }
                }
            }

            .img-container{
                width: 100%;
                overflow-x: auto;
                display: -moz-box;
                display: -webkit-box;
                display: -moz-inline-box;
                display: -webkit-inline-box;
                border-radius: 16px;
                padding: 5px;


                .img-inset{
                    height: 250px;
                    width: 250px;
                    padding: 5px;
                    position: relative;

                    &.m1{
                        height: 100%;
                        width: 100%;
                    }

                    &.m2{
                        width: 50%;
                    }

                    &.m3{
                        width: 33.3333%;
                    }

                    .img{
                        border-radius: 16px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .remove{
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        .mat-icon-button{
                            background-color: #00000042;
                            color: #fff !important;
                        }
                    }
                }

            }

            .edit-photo{
                position: absolute;
                top: 10px;
                left: 10px;

                // .mat-icon-button{
                //     color: #8D8D8D !important;
                // }

                .mat-flat-button{
                    border-radius: 16px;
                    background-color: #00000042 !important;
                    color: #fff !important;
                }
            }


        }
    }
}

.share-post{
    .new-post{
        overflow-y: auto;
        min-height: 300px;
        max-height: 500px;

        .form-parent{
            width: 100%;
            overflow-x: hidden;
        }
    }

    .my-card{
        margin-top: 15px;
        box-shadow: none !important;
        border: 1px solid #ccc !important;
    }
}
