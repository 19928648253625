$dark-green: #0f562b; // New-Color-Primary
$light-green: #198A45; // New-Color-Secondary
$dark-blue: #003640; // color-primary
$light-blue: #006879; // color-secondary
$blue-grey: #4a6268;
$green: #3f7627;
$red: #ba1b1b;
$yellow: #d6ad25;
$grey: #979797;
$light-grey: #f6f6f6;
$light-grey-bg: #fafafa;
$medium-grey: #dddddd;
$light-blue-grey: #edf3f4;
$light-grey-line: #e0e0e0;
$black: #000000;
$color_warn: #D0A303;
$green: #146E37;
$dark-green-glass: #0f562b1a;

// status
$color-red: #FF0000;
$color-success: #009933;
$color-pending: #FEBF10;
