@use "abstracts/variables" as *;
@use "base/font" as *;
@use "mixin/breakpoint" as *;

.mat-dialog-title.dialog-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  background: none;
  height: auto;
  margin: 0;
  position: relative;

  mat-icon {
    cursor: pointer;
  }

  button.close-x {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}

.dialog-header {
  .act-left {
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
}

mat-divider.mat-divider.mat-divider-horizontal {
  margin-bottom: 10px;
}

mat-dialog-container.mat-dialog-container {
  border-radius: 15px;
  padding: 25px;
  box-shadow: none;
  position: relative;
  // max-height: 90vh !important;

  .back-page {
    display: flex;
    align-items: center;
    gap: 10px;
    :hover {
      cursor: pointer;
    }

    h3.mat-h3 {
      margin: 0;
    }
  }

  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;

    .btn-save {
      background-color: $color-complete;
    }

    &.custom {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.full-screen-modal {
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

.large-modal {
  width: 1014px;
  overflow: auto;

  .mat-dialog-container {
    .mat-dialog-actions {
      height: 69px;

      button.mat-flat-button,
      button.mat.stroked-button {
      }
    }
  }
}

.medium-modal {
  width: 804px;

  .mat-dialog-container {
    .mat-dialog-actions {
      button.mat-flat-button,
      button.mat-stroked-button {
      }
    }
  }

  &.modal-with-tab {
    .mat-dialog-container {
      .mat-tab-group {
        height: 100%;

        .mat-tab-body-wrapper {
          height: 100%;
          min-height: 296px;

          .form-group {
            .row {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.medium-modal-2 {
  width: 818px;
  mat-dialog-container.mat-dialog-container {
    padding: 20px;
    .mat-dialog-content {
      margin: 0 -20px;
      padding: 0 20px;
    }

    .mat-dialog-actions {
      margin: 24px 0 0;
      padding: 0;
    }
  }
}

.small-modal-2 {
  mat-dialog-container.mat-dialog-container {
    padding: 0px;
    min-height: 462px;
  }
}

.small-modal {
  width: 500px;

  .mat-dialog-container {
    padding: 30px;

    button.mat-flat-button,
    button.mat-stroked-button {
    }
  }
}

.small-x-modal {
  width: 430px;

  // .mat-dialog-container {
  //   padding: 20px;
  // }

  // .dialog-header {
  //   button.close-x {
  //     position: absolute;
  //     top: 14px;
  //     right: 12px;
  //     cursor: pointer;
  //   }
  // }
}

.image-viewer-dialog {
  max-width: none !important;
  max-height: none !important;
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    border-radius: 0 !important;
    padding: 0 !important;
    background: rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(15px);
    position: relative;
  }
}

.mat-dialog-content {
  &.overflow-hidden {
    overflow: hidden;
  }
}

/** Extra Large Screen up **/
@include media-breakpoint-up(xl) {
  .large-modal {
    max-height: 90vh !important;
  }
}

/** Medium Screen **/

@include media-breakpoint-down(md) {
  .large-modal {
    max-width: 90vw !important;
    max-height: 80vh !important;
  }

  .medium-modal-2 {
    max-width: 95vw !important;
    max-height: 90vh !important;
  }

  mat-dialog-container.mat-dialog-container {
    // padding: 20px;
  }
}

/** XS Screen **/

@include media-breakpoint-down(xs) {
  .large-modal {
    max-width: 90vw !important;
    max-height: 90vh !important;
  }

  .medium-modal {
    max-width: 90vw !important;
  }
}
