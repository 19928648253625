@use "abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;

// :root easy to use everywhere
:root {
  --dark-green: #$dark-green; // New-Color-Primary
  --light-green: $light-green; // New-Color-Secondary
  --dark-blue: #003640; // color-primary
  --light-blue: #006879; // color-secondary
  --blue-grey: #4a6268;
  --green: #3f7627;
  --red: #ba1b1b;
  --yellow: #d6ad25;
  --grey: #979797;
  --light-grey: #f6f6f6;
  --light-grey-bg: #fafafa;
  --medium-grey: #dddddd;
  --light-blue-grey: #edf3f4;
  --light-grey-line: #e0e0e0;
  --black: #000000;
  --white: #ffffff;
  --color_warn: #d0a303;

  // status
  --color-red: #ff0000;
  --color-success: #009933;
  --color-pending: #febf10;

  // product
  --product-detail-image-width: 400px;
  --product-detail-image-height: 270px;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

body {
  margin: 0;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
  font-family: Roboto, Khmer;
  font-weight: 400;
}

ul li {
  list-style-type: none;
}

@font-face {
  font-family: "Khmer";
  src: url("/assets/fonts/KHMEROSBATTAMBANG.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.app-loading {
  z-index: 1050;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #3333;
  top: 0;
  left: 0;
  background-image: url("/assets/imgs/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
}

app-main {
  & + * {
    margin-top: 100px;
    margin-left: $menu-width !important;
  }
}

.page-not-found {
  display: flex;
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.horizontal {
  width: 100%;
  height: 1px;
  background-color: $color-light-gray;
  margin-bottom: 1rem;
}

.dot3-icon {
  width: 8px;
  height: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.nowrap {
  width: 100% !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-form-field {
  line-height: 1;

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    align-self: center;

    .mat-icon {
      color: $color-gray;
    }
  }

  .input-button {
    position: absolute;
    top: 0.25em;
    right: 0px;
  }
}

.caption-sm {
  font-size: $fs-small;
  color: $color-gray;
}

.caption-x-sm {
  font-size: $fs-x-small;
  color: $color-gray;
}

.caption-x-sm-ellipses {
  max-width: 125px;
  overflow: hidden;
  font-size: $fs-x-small;
  color: $color-gray;
}

.text-x-sm-ellipses {
  max-width: 125px;
  overflow: hidden;
}

.no-product {
  color: $medium-grey;
}

.caption-md {
  font-size: $fs-normal;
  color: $color-gray;

  &.color-light-green {
    color: $light-green;
  }

  &.fw-bold {
    font-weight: $fw-bold;
  }

  &.color-black {
    color: rgba($color: #000000, $alpha: 0.87);
  }
}

.caption-lg {
  font-size: $fs-regular;
  color: $color-gray;

  &.fw-bold {
    font-weight: $fw-bold;
  }

  &.color-black {
    color: #000000;
  }
}

.mat-tooltip {
  background-color: #000000b3;
  font-size: 12px;
}

.app-root-content {
  height: 100vh;
  overflow: auto;
}

.dash-row {
  margin-top: $menu-height;
  height: calc(100% - #{$menu-height});
  overflow-y: auto;
}

.mat-error,
.mat-hint {
  line-height: 16px;
}

.mat-error-mat-error {
  color: $color-danger;
}

.no-pointer-events {
  pointer-events: none;
  background: none;
  border: none;
}

.required::after {
  content: " *";
  color: $color-danger;
  font-weight: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e8ba8;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary;
  border-radius: 5px;
}

// .mat-form-field-appearance-legacy .mat-form-field-underline {
//   // background-color: $color-tertiary;
//   background-color: $color-form-underline;
// }

.mat-form-field-appearance-legacy {
  .mat-form-field-flex input.mat-input-element {
    line-height: 2em;
  }

  .mat-form-field-underline {
    background-color: $color-form-underline !important;
  }
}

.mat-form-field-appearance-legacy.mat-form-field-invalid {
  .mat-form-field-underline {
    background-color: $color-danger;
  }
}

mat-form-field.custom-input-underline.mat-form-field.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    border: 1px solid #ffffff;

    .mat-form-field-infix {
      input.mat-input-element {
        background-color: #ffffff;
      }
    }
  }

  &.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-underline {
      border: 1px solid $color-danger;
    }
  }
}

.custom-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid $color-medium-gray;

  input[type="text"] {
    width: 100%;
    outline: 0;
    border-width: 0;
    padding: 10px 0;
    font-size: $fs-normal;
    background-color: transparent;
  }

  &.multiple-select {
    width: auto;
    border: none;
    margin-left: 4px;
    flex: 1;
  }
}

.mat-chip-list.custom-chip-1-wrap {
  .mat-chip-list-wrapper {
    margin: 0;
    display: flex;
    gap: 4px;
    margin-bottom: 4px;

    .mat-chip.mat-standard-chip {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 4.5px;

      border-radius: 10px;
      border: 1px solid rgba(0, 54, 64, 0.1);
      background: rgba(0, 54, 64, 0.05);
      font-weight: $fw-normal;
      margin: 0;
    }
  }
}

.pagination {
  width: 100%;

  .mat-select-arrow,
  .mat-select-value {
    color: #ffffff;
  }
}

.table-order {
  thead {
    tr {
      box-shadow: 0 10px $background-color;
    }
  }

  tbody:before {
    content: "-";
    display: block;
    line-height: 10px;
    background-color: $background-color;
    text-indent: -99999px;
  }
}

.custom-radio-button {
  .mat-radio-label {
    span.mat-radio-label-content {
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }
}

.box-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-template-rows: repeat(auto-fill, 100px);
  grid-gap: 20px;
}

.box-item {
  display: flex;
  gap: 10px;
  height: 90px;
  border-radius: 15px;
  align-items: center;
  padding: 15px;
  background-color: white;

  .box-pic {
    height: 70px;
    width: 70px;
    border-radius: 10px;
    flex: 0 0 70px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .profile-pic {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: none;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  .box-desc {
    flex: 1 1 150px;
    cursor: pointer;
    min-width: 0;
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: space-around;

    .box-desc-title {
      font-size: $fs-normal;
      font-weight: $fw-bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-height: 24px;
    }

    .box-desc-price {
      font-size: $fs-small;
      height: 23px;
    }

    .caption-sm {
      height: 23px;
    }
  }
}

.box-container-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(auto-fill, 76px);
  grid-gap: 10px 8px;
}

.box-item-2 {
  display: flex;
  gap: 12px;
  height: 76px;
  border-radius: 12px;
  align-items: center;
  padding: 12px;
  background-color: white;
  cursor: pointer;

  .box-pic {
    height: 52px;
    width: 52px;
    border-radius: 10px;
    flex: 0 0 52px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .profile-pic {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    border: none;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  .box-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    cursor: pointer;
    min-width: 0;

    .box-desc-title {
      font-size: $fs-normal;
      font-weight: $fw-bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 25px;
      font-style: normal;
      line-height: normal;
      width: 100%;
    }

    .caption-sm {
      font-weight: 400;
      line-height: normal;
      height: 22px;
    }
  }
}

.mat-select-value {
  line-height: 2;
}

.mat-select-placeholder {
  color: #dddddd !important;
}

.mat-form-field-underline {
  width: 0;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before,
.mat-form-field-appearance-fill .mat-form-field-ripple {
  background-color: transparent !important;
}

// swiper product suggestion
.swiper.swiper-product-suggestion {
  height: 200px;

  .swiper-wrapper {
    cursor: pointer;

    .swiper-slide {
      width: 138px;
    }
  }
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: white;
  opacity: unset;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
  background-color: $dark-green;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  border: 1px solid $light-green;
}

.swiper.swiper-content {
  height: 250px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $dark-blue;
}

.btn-search {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-color: rgba(0, 54, 64, 0.1);

  mat-icon.mat-icon {
    svg {
      path {
        stroke: $color-primary;
      }
    }
  }
}

.search-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 4px;
  height: 100%;

  img {
    width: 72px;
    height: 72px;
  }

  .no-result-text {
    color: $color-primary;
    font-size: 20px;
    font-weight: $fw-bold;
    margin-top: 8px;
  }

  p {
    color: #979797;
    text-align: center;
    font-size: $fs-normal;
  }

  &.search-product {
    height: 100px;
    padding: 15px 20px;

    .box-pic {
      height: 70px;
      width: 70px;
      border-radius: 15px;
      flex: 0 0 70px;

      img {
        border-radius: 15px;
      }
    }
  }
}

.mgt12 {
  margin-top: 12px;
}

.link-back-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  mat-icon.mat-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.mat-radio-group.payment-gateway-options {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .mat-radio-label-content.mat-radio-label-before {
    margin-right: auto;
  }

  .mat-radio-button {
    padding: 12px;
    height: 62px;
    line-height: normal;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(234, 234, 234, 0.5);

    .bc-list {
      margin-right: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      line-height: normal;

      .bc-profile {
        width: 38px;
        height: 38px;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .bc-profile-info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: space-between;
        gap: 4px;

        .bc-number {
          font-weight: $fw-bold;
          color: $color-secondary;
          height: 17px;
        }

        .bc-name {
          height: 17px;
          color: rgba(83, 83, 95, 0.3);
          font-size: $fs-normal;
          font-style: normal;
          font-weight: $fw-normal;
          line-height: normal;
        }
      }

      .bc-option {
        width: 18px;
      }

      &.cod {
        .bc-profile {
          width: 46px;
          height: 46px;
        }

        .bc-profile-info {
          .bc-name,
          .bc-number {
            height: 21px;
          }

          .bc-name {
            line-height: 150%;
          }
        }
      }
    }

    &.radio-cod {
      height: 70px;
    }

    &.mat-radio-checked {
      border: 1px solid $color-primary;
    }
  }
}

.main-search {
  .filter-date {
    mat-datepicker-toggle.mat-datepicker-toggle {
      button.mat-icon-button {
        width: 24px;
        height: 24px;
        line-height: normal;
        margin-left: 16px;
        margin-right: 12px;
      }
    }
  }
}

.mat-radio-button {
  &.radio-type-1 {
    .mat-radio-container {
      width: 18px;
      height: 18px;

      .mat-radio-inner-circle {
        height: 18px;
        width: 18px;
      }

      .mat-radio-outer-circle {
        width: 18px;
        height: 18px;
        border-width: 1px;
        border-color: #eaeaea;
      }

      .mat-ripple {
        .mat-ripple-element.mat-radio-persistent-ripple {
          opacity: 0;
        }
      }
    }
  }
}

.input-1 {
  .input-group {
    width: 100%;
    border-bottom: 1px solid rgba(74, 98, 104, 0.1);
    display: flex;
    gap: 20px;
    font-size: 14px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 4px;

    .mat-chip-list {
      width: 100%;

      mat-chip-list.mat-chip-list {
        width: 100%;
      }
    }
  }
}

.input-1 .input-group.with-select .mat-select {
  &.unit-select,
  &.currency-select {
    .mat-select-trigger {
      .mat-select-value {
        color: $color-secondary;
        font-size: $fs-normal;
        line-height: 170%;
      }
    }
  }

  &.unit-select {
    width: 81px;
  }

  &.currency-select {
    width: 57px;
  }
}

mat-chip.mat-chip.custom-chip-1 {
  mat-icon.mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    cursor: pointer;
    opacity: 1;
  }
}

.filter-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  // height: 100%;
  height: calc(100% - 30px);

  img {
    width: 100px;
    height: 100px;
  }

  .fnr-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    span:nth-child(1) {
      color: $color-secondary;
      text-align: center;
      font-size: $fs-regular;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 170%;
      height: 31px;
    }

    span:nth-child(2) {
      color: #979797;
      font-size: $fs-small;
      font-style: normal;
      font-weight: $fw-normal;
      line-height: 170%;
      height: 24px;
    }
  }
}

.h-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

.fw-bold {
  font-weight: $fw-bold;
}

mat-tab-group.mat-tab-group.product-rating-tab {
  width: 100%;
  height: 100%;

  .mat-tab-header {
    .mat-tab-label-container {
      flex-grow: 0;

      .mat-tab-list {
        .mat-tab-labels {
          gap: 4px;

          .mat-tab-label {
            height: 45px;
            color: #000;
            font-size: $fs-regular;
            font-style: normal;
            font-weight: $fw-normal;
            line-height: normal;
            opacity: 1;

            &.mat-tab-label-active {
              .mat-tab-label-content {
                color: $color-primary;
                font-size: $fs-regular;
                font-style: normal;
                font-weight: $fw-bold;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    height: 100%;

    mat-tab-body.mat-tab-body {
      overflow-y: hidden;

      .mat-tab-body-content {
        overflow-y: hidden;
      }
    }
  }
}

mat-icon.mat-icon {
  &.rated {
    path {
      fill: #ffb400;
    }
  }
}

.box-reviewer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .box-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;

    .profile {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;

      .image {
        width: 30px;
        height: 30px;
        border-radius: 25px;
        border: 1px solid rgba(74, 98, 104, 0.1);

        img {
          border-radius: 25px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .action {
        margin-left: auto;

        button {
          margin: 0;
        }
      }

      span {
        color: $color-secondary;
        font-size: $fs-normal;
        font-style: normal;
        font-weight: $fw-bold;
        line-height: normal;
      }
    }

    .rate-star {
      display: flex;
      padding: 0px 42px;
      align-items: flex-start;
      gap: 4px;

      mat-icon.mat-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .box-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .rating-body-content {
      display: flex;
      padding: 2px 42px;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;

        span {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .img {
          width: 57px;
          height: 76px;
          border-radius: 8px;
          position: relative;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
          }

          mat-icon.mat-icon {
            position: absolute;
            display: none;
            right: 0;
            top: 0;
            width: 15px;
            height: 15px;
            line-height: inherit;
            cursor: pointer;
          }

          &:hover {
            mat-icon.mat-icon {
              display: block;
            }
          }
        }
      }
    }

    .rating-body-footer {
      display: flex;
      padding: 0px 42px;
      align-items: flex-start;
      gap: 12px;

      .created-date {
        color: #979797;
        text-align: center;
        font-size: $fs-small;
        font-style: normal;
        font-weight: $fw-normal;
        line-height: normal;
      }

      .like,
      .comment {
        color: $color-secondary;
        text-align: center;
        font-size: $fs-small;
        font-style: normal;
        font-weight: $fw-normal;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  &.child {
    padding-left: 44px;

    &:first-child {
      margin-top: 12px;
    }
  }
}

.star-item {
  mat-icon.mat-icon {
    svg {
      path {
        fill: #979797;
      }
    }
  }

  &.active {
    mat-icon.mat-icon {
      svg {
        path {
          fill: #ffb400;
        }
      }
    }
  }
}

.position-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

@for $i from 1 to 3 {
  .leading-#{$i} {
    line-height: $i;
  }
}

@each $breaker, $breakpoint in $grid-breakpoints {
  @media screen and (max-width: $breakpoint) {
    .overflow-x-#{$breaker}-auto {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

#map {
  height: 100%;
}

.required-msg {
  font-size: 12px;
  color: #ba1b1b;
  &::before {
    content: "*";
  }
}

.cursor-pointer {
  cursor: pointer;
}

// .ml-auto {
//   margin-left: auto;
// }

// .mat-icon-button {
//   line-height: 1 !important;
// }

/** Medium screen or Mobile Screen **/

@include media-breakpoint-down(md) {
  .swiper.swiper-product-suggestion .swiper-wrapper .swiper-slide {
    width: 110px;
  }

  .overflow-x-auto-y-hidden-md {
    overflow-y: hidden;
    overflow-x: auto;
  }
}

/** Small Screen **/

@include media-breakpoint-down(sm) {
  .box-container-2 {
    grid-template-columns: 100%;
  }
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline {
  border-top: 0;
  line-height: 2;
  padding: 8px 0;
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        top: 0;
        .mat-form-field-outline-start {
          border-radius: 12px 0 0 12px;
          min-width: 15px;
        }

        .mat-form-field-outline-end {
          border-radius: 0 12px 12px 0;
          min-width: 15px;
        }
      }
      .mat-form-field-suffix {
        align-self: center;
      }

      .mat-form-field-prefix {
        top: 0;
        align-self: center;
        margin-right: 12px;

        .mat-icon {
          font-size: 100%;
        }
      }

      .mat-form-field-infix {
        border-top: 0;
        padding: 11px 0;
        width: 0px;
      }
    }

  }

  &.custom-picker {
    .mat-form-field-wrapper {

      .mat-form-field-flex {
        .mat-form-field-outline {
          top: 0;

          .mat-form-field-outline-start {
            border-radius: 5px 0 0 5px;
          }

          .mat-form-field-outline-end {
            border-radius: 0 5px 5px 0;
            min-width: 15px;
          }
        }

        .mat-form-field-infix {
          padding: 3px 0;
        }
      }

    }

    &.custom-year-picker {
      width: 75px;
      .mat-form-field-wrapper {
        width: 82px;
      }
    }
  }
  .mat-select-arrow-wrapper {
    transform: translateY(-6%);
  }
}

.hidden-scrollbar-y {
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Edge
  // For Webkit browsers (Chrome, Safari)
  &::-webkit-scrollbar {
        display: none;
  }
}

.base-shadow {
  border: 1px solid white;
  -webkit-box-shadow: 0.5px 0.5px 10px 3px #d2dcfd;
  -moz-box-shadow: 0.5px 0.5px 10px 3px #d2dcfd;
  box-shadow: 0.5px 0.5px 10px 2px #d2dcfd;
}
