$fw-normal: 400;
$fw-semi-bold: 600;
$fw-bold: 700;

$fs-x-small: 11px;
$fs-small: 12px;
$fs-normal: 14px;
$fs-regular: 16px;
$fs-medium: 18px;
$fs-large: 25px;
$fs-xl: 30px;
$fs-xxl: 36px;
