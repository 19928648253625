@use "abstracts/variables" as *;
@use "abstracts/mixins" as *;
@use "base/font" as *;
@use "base/font" as *;
@use "base/colors" as *;

.account-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, $bg-green 0%, $color-primary 100%);
  color: #ffffff;
  .section-left,
  .l-w-left,
  .r-l-w-left,
  .f-l-w-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 23%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      margin-bottom: 40px;
    }
    .title {
      display: flex;
      h1.mat-h1 {
        margin-top: 20px;
        margin-left: 10px;
      }
    }
  }
  .section-right,
  .r-l-w-right,
  .f-l-w-right {
    background: rgba(0, 0, 0, 0.25);
    padding: 50px;
  }

  .section-right,
  .l-w-right,
  .r-l-w-right,
  .f-l-w-right {
    width: 54%;
    height: 100%;
    position: relative;
    .mat-input-element::placeholder {
      color: $color-tertiary;
    }

    .mat-input-element {
      caret-color: #ffffff;
    }

    .mat-form-field-ripple {
      background-color: unset;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      height: 1.77px;
    }

    .switch-lang {
      position: fixed;
      cursor: pointer;
      bottom: 5%;
      right: 4%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
