@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-image-contain($url) {
  @include background-image($url);
  background-size: contain;
}

@mixin background-image-cover($url) {
  @include background-image($url);
  background-size: cover;
}
@mixin hover-overlay($color: #000000) {
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1);
    opacity: 0;
    background-color: $color;
    content: "";
  }
  &:hover {
    &:after {
      opacity: 0.08;
    }
  }
}
@mixin status-label($color) {
  color: $color;
  position: relative;
  margin-left: 22px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -22px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color;
    transform: translateY(-50%);
  }
}
@mixin academic-completion-status($color, $background-color) {
  background-color: $background-color;
  color: $color;
  padding: 10px 20px;
  border-radius: 12px;
}

@mixin mat-icon-button($size) {
  line-height: initial;
  min-width: auto;
  height: #{$size}px;
  width: #{$size}px;
  .mat-button-wrapper {
    line-height: initial;
    mat-icon {
      height: auto;
      width: auto;
      line-height: initial;
      font-size: #{$size * 0.6}px;
    }
  }
}

@mixin product-item-table($fn-size) {
  .product-item-table {
    width: 100%;
    font-size: $fn-size;
    .mat-table {
      border-spacing: 8px;
    }
    .product-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .img-section {
        width: 40px;
        height: 40px;
      }
      .caption-md {
        padding-right: 17px;
      }
    }
    tr.mat-header-row {
      margin-bottom: 8px;
      height: auto;
    }
    th {
      font-size: $fn-size;
    }
    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 0;
      min-width: 55px;
    }
    th.mat-header-cell,
    td.mat-cell {
      border-bottom: none;
      &:not(:nth-child(0), :nth-child(1)) {
        min-width: 92px;
      }
    }
    tbody {
      padding: 8px 0;
    }
  }
}

@mixin image-section($width: 62px, $height: 60px) {
  .img-section {
    width: $width;
    height: $height;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      border: 1px solid rgba(74, 98, 104, 0.1);
    }
  }
}

@mixin text-ellipsis($maxWidth: 120px) {
  max-width: $maxWidth;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin position-sticky($top: 0) {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: $top;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  z-index: 10;
  backface-visibility: hidden;
}
