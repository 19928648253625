@use "../abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;
@use "abstracts/mixins" as *;
@use "mixin/flex" as *;
@use "mixin/breakpoint" as *;

$card-radius: 10px;

@mixin flexCenter() {
    @include flexbox;
    @include align-items(center);
    gap: 8px;
}

.order-detail {
    @include image-section(42px!important, 42px!important);
    app-order-tracking-card {
        .order-tracking-card {
            border-radius: $card-radius;
            background-color: #fff;
            min-height: 170px !important;
            .caption-lg {
                padding: 10px 0px 10px 20px;
            }
        }
    }

    app-product-summary-card {
        .product-summary {
            @include image-section(42px!important, 42px!important);
            @include product-item-table($fs-normal);
            .txt-ellipsis {
                @include text-ellipsis(120px);
            }
        }
    }

    .custom-card {
        padding: 0;
        border-radius: $card-radius;
        mat-card-title {
            padding: 10px 0px 10px 20px;
        }
        mat-card-content {
            padding: 10px 20px 12px 24px;
        }

        .mat-divider-horizontal {
            position: relative;
            left: 0;
            width: 100%;
        }
    }

    .total {
        padding-right: 20px;
        .caption-md {
            &.value {
                font-weight: $fw-bold;
                color: $dark-blue;
            }
        }
    }

    .payment-type {
        @include flexCenter;
        gap: 8px;
        .img-section {
            width: 24px !important;
            height: 23.25px !important;
        }
    }

    .profile {
        @include flexCenter;
        .img-section {
            width: 24px;
            height: 24px;
            img {
                border-radius: 50%;
            }
        }
    }
}

.order-information {
    padding: 10px 0px 15px 0px !important;
    --img-size-sm: 50px;
    .profile {
        .img-section {
            width: 60px !important;
            height: 60px !important;
            min-width: 60px;
            @include media-breakpoint-down(md) {
                width: var(--img-size-sm) !important;
                height: var(--img-size-sm) !important;
                min-width: var(--img-size-sm);
            }
        }
        &:hover {
            cursor: pointer;
        }
    }

    .caption-lg {
        padding-left: 20px;
    }

    .content {
        padding: 0 20px;
    }

    .delivery-to-customer {
        border-radius: 10px;
        background-color: $color-secondary;
        color: white;
        font-size: $fs-normal;
        padding: 3px 20px;
        width: max-content;
    }

    .txt-ellipsis {
        @include text-ellipsis(200px);
    }
}

/** Delivery section **/

.delivery-box {
    display: flex;
    height: 123px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 136px;

    border-radius: 8px;

    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
    // color: #ffffff;
    background: #fff;

    .svg-file {
        width: 24px;
        height: 24px;
    }

    &.is_active {
        // border: 2px solid #f373a0;
        color: #ffffff;
        background: var(--color-primary-gradient, linear-gradient(175deg, #003640 0.58%, rgba(0, 54, 64, 0.75) 100%));
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: 1ms ease-in-out;
        border: 1px solid rgba($color: #003640, $alpha: 0.8);
        // color: #ffffff;
        // background: var(--color-primary-gradient, linear-gradient(175deg, #003640 0.58%, rgba(0, 54, 64, 0.75) 100%));
    }
}

/** Delivery Info **/

.delivery-info {
    @include image-section(42px!important, 42px!important);
    .card {
        border-radius: 15px;
        background: #fff;
        padding: 20px;
    }

    .order-main-content {
        .identify-location {
            .map-container {
                height: 300px;
                margin-bottom: 12px;
            }
        }
    }

    .delivery-type {
        @include flexCenter;
        gap: 8px;
        .img-section {
            width: 24px !important;
            height: 23.25px !important;
        }
    }

    app-product-summary-card {
        .product-summary {
            @include image-section(42px!important, 42px!important);
            @include product-item-table($fs-normal);
            .txt-ellipsis {
                @include text-ellipsis(120px);
            }
        }

        .mat-card {
            margin: 0;
            padding: 0;
        }

        .mat-card-content {
            margin-top: 12px;
        }

        .total {
            padding-right: 20px;
            padding-top: 12px;
            .caption-md {
                &.value {
                    font-weight: $fw-bold;
                    color: $dark-blue;
                }
            }
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-flex input.mat-input-element {
        line-height: 1.6em;
    }

    .mat-form-field-infix {
        padding: 0.5em 0;
        border-top: 0em solid transparent;
    }

    .form-control {
        .mat-form-field-infix {
            display: flex;
            border-top: 0px;
            color: $dark-blue;
            .custom-select {
                max-width: 65px;
            }
            .mat-select-arrow,
            .mat-select-value {
                color: $dark-blue;
            }
        }
        .mat-form-field-flex {
            gap: 8px;
        }
    }
}

.map-direction {
    border-radius: 10px;
    border: 1px solid rgba(74, 98, 104, 0.1);
    padding: 10px 12px;

    .mat-step-icon {
        height: 40px;
        width: 40px;
        .mat-icon {
            --icon-size: 24px;
            font-size: var(--icon-size);
            height: var(--icon-size);
            width: var(--icon-size);
        }
    }

    .step-body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .image-box {
            width: 90px;
            height: 64px;
            border-radius: 8px;
            &:hover {
                cursor: pointer;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }
    }

    .mat-vertical-stepper-content {
        visibility: visible !important;
        overflow: unset !important;
        outline: 0;
        display: flex;
        min-height: 36px;
        height: auto !important;

        &:hover {
            cursor: pointer;
        }
    }

    .mat-vertical-content-container {
        padding-top: 0px !important;
    }

    .mat-vertical-content {
        padding: 0 24px 0px 34px;
    }

    .mat-step-icon-content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        .thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        background-color: transparent;
    }

    .mat-stepper-vertical-line::before {
        border-left-color: rgba(74, 98, 104, 0.1);
    }

    .mat-vertical-stepper-header {
        display: flex;
        align-items: center;
        height: 24px;

        .sub-title {
            display: flex;
            align-items: center;
            gap: 8px;

            .mat-divider.mat-divider-vertical {
                height: 14px;
            }
        }
    }

    .mat-vertical-content-container {
        margin-left: 20px;
        padding-top: 14px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
        padding: 8px 0px;
    }

    .mat-step-header .mat-step-icon,
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: #f4f7ff;
        color: white;
        border: 2px solid #ffffff;
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled="false"] {
        background-color: transparent;
        cursor: default;
    }

    .mat-step-label {
        &:hover {
            cursor: pointer;
        }
    }
}

.choose-destination {
    #chooseMap {
        height: 100%;
    }

    .map-container {
        height: 350px;
        width: 700px;
    }
}

.delivery-btn {
    width: 100%;
}

/** Medium Screen **/

@include media-breakpoint-down(md) {
    .list-container {
        .list-top {
            form {
                width: 100%;
            }

            .main-search {
                padding: 10px;
                gap: 10px;
                flex-direction: column;
                height: auto;

                mat-select {
                    min-width: 140px;
                }

                input {
                    min-width: 100px;
                }

                .filter-date mat-datepicker-toggle.mat-datepicker-toggle button.mat-icon-button {
                    margin-left: 0;
                    // margin-right: 12px;
                }

                .action {
                    margin-left: auto !important;
                }
            }
        }
    }

    .choose-destination {
        // #chooseMap {
        //     height: 100%;
        // }

        .map-container {
            height: 350px;
            width: 65vw;
        }
    }
}
