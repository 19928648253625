@use "base/colors" as *;
@use "abstracts/variables" as *;
@use "base/font" as *;
@use "mixin/breakpoint" as *;
@use "sass:math";
@import "abstracts/dimensions";

$btn-sm: 35px;
$btn-md: 45px;
$btn-md-1: 41px;
$btn-lg: 50px;

.mat-icon-button.btn-round {
  width: $btn-sm;
  height: $btn-sm;
  line-height: 2;
  border-radius: 10px;
  border-width: 0;

  &.mat-primary {
    background-color: $color-primary;
    color: white;
  }

  &.unsaved {
    background: #00313b62;
    backdrop-filter: blur(20px);
    color: #007994;
  }

  &.btn-delete {
    background: rgba(186, 27, 27, 0.1);
    backdrop-filter: blur(5px);

    mat-icon.mat-icon {
      &.svg-path {
        &.svg-fill {
          path {
            fill: $color-danger;
          }
        }
      }
    }
  }

  &.btn-edit {
    background: rgba(214, 173, 37, 0.1);
    color: $color-btn-edit;
  }

  &.btn-blur {
    background: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(5px);// chrome bug, flickering
    color: white;
  }
}

.mat-icon-button.btn-round-2 {
  width: 32px;
  height: 32px;
  line-height: normal;
  flex-shrink: 0;
  border-radius: 8px;

  &.btn-do {
    border: 1px solid $color-primary;
  }

  &.btn-done {
    background-color: $color-primary;
  }

  mat-icon.mat-icon {
    width: 21.943px;
    height: 21.943px;
    flex-shrink: 0;
  }
}

.mat-icon-button.btn-cycle {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 15px;
  border-width: 0;

  &.btn-camera {
    background-color: $dark-blue;
  }
}

.mat-stroked-button,
.mat-flat-button {
  &.btn-flat {
    background: rgba(0, 104, 121, 0.05);
    color: $color-secondary;
  }
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
  &.mat-button-base {
    border-radius: 10px;
  }

  span.mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button,
.mat-icon-button,
.mat-fab,
.mat-mini-fab {
  &.btn-lg {
    height: $btn-lg;
    font-size: $fs-normal;
  }

  &.btn-md-1 {
    height: $btn-md-1;
    font-size: $fs-normal;
  }

  &.btn-md {
    height: $btn-md;
    font-size: $fs-normal;
    padding: 0 14px;
  }

  &.btn-sm {
    height: $btn-sm;
    font-size: $fs-normal;
  }

  &.btn-secondary-opacity {
    background: rgba(0, 104, 121, 0.05);
    color: $color-secondary;
  }

  &.btn-subscribed {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 54, 64, 0.1);
    color: $color-primary;
    padding: 5px 20px;
    border-radius: 10px;
    height: $btn-md-1;
    gap: 10px;
  }

  &.btn-unsubscribed {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $color-primary;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    height: $btn-md-1;
    gap: 10px;
  }

  &.btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    color: white;
    border-radius: 15px;
    height: $btn-md;
    gap: 10px;

    &-filter {
      padding: 8px 16px;
      height: 41px;
      background-color: $color-primary;
    }

    &-receive-money {
      background-color: #00ab26;
      padding: 10px 16px;
      height: 44px;
      border-radius: 10px;
    }
  }

  &.border-color {
    &--primary {
      border-color: $dark-blue !important;
    }
  }

  mat-icon.mat-icon {
    &.svg-rect {
      &.svg-fill {
        rect {
          fill: $color-secondary;
        }
      }
    }

    &.svg-path {
      &.svg-fill {
        path {
          fill: $color-secondary;
        }
      }

      &.svg-stroke {
        path {
          stroke: $color-secondary;
        }

        &.color-white {
          path {
            stroke: #fff;
          }
        }

        &.color-black {
          path {
            stroke: #000;
          }
        }

        &.color-primary {
          path {
            stroke: $color-primary;
          }
        }
      }

      &.svg-stoke-current-color {
        path {
          stroke: currentColor;
        }
      }
    }
  }

  &.mat-primary {
    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: #ffffff;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: #ffffff;
          }
        }

        &.svg-stroke {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  &.btn-edit {
    background: rgba(214, 173, 37, 0.1);
    backdrop-filter: blur(5px);
    color: $color-btn-edit;
  }

  &.btn-edit-1 {
    background-color: #fda40d;
    color: white;
    height: 45px;
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 14px;
  }

  &.mat-button-disabled {
    background-color: $disabled-btn-color !important;
    color: $disabled-text-color;

    &.mat-primary {
      background-color: $disabled-btn-color !important;
      color: $disabled-text-color;

      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $disabled-text-color;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $disabled-text-color;
            }
          }

          &.svg-stroke {
            path {
              stroke: $disabled-text-color;
            }
          }
        }
      }
    }

    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: $disabled-text-color;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: $disabled-text-color;
          }
        }

        &.svg-stroke {
          path {
            stroke: $disabled-text-color;
          }
        }
      }
    }
  }

  &.btn-light {
    background-color: rgba(0, 54, 64, 0.1);
  }
}

.mat-card {
  .action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .right {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
}

.custom-audio-player {
  mat-icon.mat-icon {
    &.secondary {
      circle {
        fill: $color-primary;
      }

      line {
        stroke: #ffffff;
      }

      g {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.btn-group {
  margin-left: auto;
  display: flex;
  gap: 12px;

  button {
    mat-spinner {
      margin-left: 5px;
      display: inline-block !important;

      circle {
        stroke: #fff !important;
      }
    }
  }
}

button.p-btn {
  &:disabled {
    background-color: $disabled-btn-color;
    color: $disabled-text-color;

    mat-icon.mat-icon {
      &.svg-path {
        &.svg-fill {
          path {
            fill: $disabled-text-color;
          }
        }

        &.svg-stroke {
          path {
            stroke: $disabled-text-color;
          }
        }
      }
    }
  }
}

.v-pagin a:hover:not(:disabled),
button.p-btn:hover:not(:disabled) {
  mat-icon.mat-icon {
    &.svg-path {
      &.svg-fill {
        path {
          fill: #ffffff;
        }
      }

      &.svg-stroke {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

/** Medium Screen **/

@include media-breakpoint-down(md) {
  .mat-button,
  .mat-raised-button,
  .mat-flat-button,
  .mat-stroked-button,
  .mat-icon-button,
  .mat-fab,
  .mat-mini-fab {
    &.btn-lg {
      height: $btn-lg;
      font-size: $fs-normal;
    }

    &.btn-md-1 {
      height: $btn-md-1;
      font-size: $fs-normal;
    }

    &.btn-md {
      height: $btn-md - 5px;
      font-size: $fs-normal;
      padding: 0 10px;
    }

    &.btn-sm {
      height: $btn-sm;
      font-size: $fs-normal;
    }

    &.btn-secondary-opacity {
      background: rgba(0, 104, 121, 0.05);
      color: $color-secondary;
    }

    &.btn-subscribed {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: rgba(0, 54, 64, 0.1);
      color: $color-primary;
      padding: 5px 20px;
      border-radius: 10px;
      height: $btn-md-1;
      gap: 10px;
    }

    &.btn-unsubscribed {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-primary;
      color: white;
      padding: 5px 20px;
      border-radius: 10px;
      height: $btn-md-1;
      gap: 10px;
    }

    &.btn {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      color: white;
      border-radius: 15px;
      height: $btn-md;
      gap: 10px;

      &-filter {
        padding: 8px 16px;
        height: 41px;
        background-color: $color-primary;
      }

      &-receive-money {
        background-color: #00ab26;
        padding: 10px 16px;
        height: 44px;
        border-radius: 10px;
      }
    }

    &.border-color {
      &--primary {
        border-color: $dark-blue !important;
      }
    }

    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: $color-secondary;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: $color-secondary;
          }
        }

        &.svg-stroke {
          path {
            stroke: $color-secondary;
          }
        }

        &.svg-stoke-current-color {
          path {
            stroke: currentColor;
          }
        }
      }
    }

    &.mat-primary {
      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: #ffffff;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: #ffffff;
            }
          }

          &.svg-stroke {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
    }

    &.btn-edit {
      background: rgba(214, 173, 37, 0.1);
      backdrop-filter: blur(5px);
      color: $color-btn-edit;
    }

    &.btn-edit-1 {
      background-color: #fda40d;
      color: white;
      height: 45px;
      border-radius: 10px;
      padding: 0px 20px;
      font-size: 14px;
    }

    &.mat-button-disabled {
      &.mat-primary {
        background-color: $disabled-btn-color !important;
        color: $disabled-text-color;

        mat-icon.mat-icon {
          &.svg-rect {
            &.svg-fill {
              rect {
                fill: $disabled-text-color;
              }
            }
          }

          &.svg-path {
            &.svg-fill {
              path {
                fill: $disabled-text-color;
              }
            }

            &.svg-stroke {
              path {
                stroke: $disabled-text-color;
              }
            }
          }
        }
      }

      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $disabled-text-color;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $disabled-text-color;
            }
          }

          &.svg-stroke {
            path {
              stroke: $disabled-text-color;
            }
          }
        }
      }
    }

    &.btn-light {
      background-color: rgba(0, 54, 64, 0.1);
    }
  }

  .btn-group {
    gap: 8px;
  }
}

@include media-breakpoint-down(xs) {
  // .mat-button,
  // .mat-icon-button,
  // .mat-stroked-button,
  // .mat-flat-button {
  //   height: 33px;
  // }
}

.mat-button-danger {
  &:hover {
    background: #FF00001a !important;
  }
  .mat-icon {
    svg {
      path {
        fill: $color-red !important;
      }
    }
  }
}
