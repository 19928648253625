@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;

@keyframes moveme {
    0% {
        transform: translateX(0) scale(0.9);
    }
    25% {
        transform: translateX(-5px) scale((1.1));
    }
    // 50% {
    //     transform: translateX(-1.2rem);
    // }
    100% {
        transform: translateX(-10px);
        transform: scale(1);
    }
}

.filter-container {
    &__inline-group {
        background: #ffffff;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $fs-normal;

        > div {
            border-right: 1px solid rgba(74, 98, 104, 0.05);
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            margin-right: 10px;
            padding-right: 20px;
            .pre-icon {
                width: 24px;
                height: 24px;
            }

            &.action {
                border: none;
                width: auto;
                padding: 0 0 0 10px;
                margin: 0;
            }
        }

        input {
            width: 100%;
            outline: 0;
            border-width: 0;
            background-color: transparent;
        }

        input::placeholder {
            font-size: $fs-normal;
            color: $medium-grey;
        }

        mat-select,
        input[type="text"],
        input[type="number"] {
            line-height: 26px;
            font-size: $fs-normal;
        }

        .submit-btn {
            width: 110px;
        }

        input.to-price {
            text-align: right;
        }
    }
    &__inline-separation {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            flex-direction: column;
            align-items: start;
            gap: 12px;
        }

        .sort-filter {
            display: flex;
            align-items: flex-end;
            .title {
                .list {
                    display: flex;
                }
            }
        }

        .search-box {
            background: #ffffff;
            border-radius: 15px;
            padding: 8px 12px;

            height: 100%;
            max-width: 400px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $fs-normal;

            > div {
                border-right: 1px solid rgba(74, 98, 104, 0.05);
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                margin-right: 10px;
                padding-right: 20px;

                .pre-icon {
                    width: 24px;
                    height: 24px;
                }

                &.action {
                    border: none;
                    width: auto;
                    padding: 0 0 0 10px;
                    margin: 0;
                }
            }

            input {
                width: 100%;
                outline: 0;
                border-width: 0;
                height: 30px;
                background-color: transparent;
            }

            input::placeholder {
                font-size: $fs-normal;
                color: $medium-grey;
            }

            .submit-btn {
                width: 110px;
            }
        }
    }
}

.sidenav-filter {
    --sidenav-width: 253px;
    --sidenav-collapsed-width: 20px;
    width: var(--sidenav-collapsed-width);
    transition: 2s all ease !important;

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
        // position: sticky;
        // position: -webkit-sticky;
        // top: 147px;
        // transition: 2s all ease;
        // position: fixed !important;
        // z-index: 100;
        // // right: -42px;
        // right: -25px;

        top: 10%;
        transition: 2s all ease;
        position: sticky !important;
        z-index: 10;
        right: -15px;
        transform: translateX(37px);
    }

    &.is-expanded {
        width: var(--sidenav-width) !important;
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        height: calc(100%-200px);
        @include media-breakpoint-down(sm) {
            padding-right: 25px;
            position: fixed !important;
            z-index: 10000;
            right: 20px;
            bottom: 0;
            top: 9%;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        margin: 0;
        gap: 12px;
    }

    .toggle-button {
        display: flex;

        align-items: center;
        justify-content: center;

        $size: 25px;
        width: $size;
        height: $size;
        margin: 0;
        padding: 0;
        animation: moveme 4s linear infinite !important;
        // transform: rotate(180deg) !important;

        position: absolute;
        top: 0;
        left: -24px;
        // left: calc($size / -2) - 2px;

        border: 1px solid gray;
        border-radius: 50%;

        background-color: white;

        cursor: pointer;

        &:hover {
            border: 2px solid $dark-blue;
        }

        &.is-flipped {
            transform: rotate(-180deg) !important;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        mat-icon {
            font-size: 1.5em;

            width: fit-content;
            height: fit-content;
        }
    }

    .filter-wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 24px;
        max-height: calc(100% - 12px);
        padding: 6px;
        color: $dark-green;

        .mat-radio-button.mat-accent .mat-radio-inner-circle {
            background-color: $dark-blue;
        }
    }

    .goods-price {
        display: flex;
        align-items: center;
        gap: 5px;

        .dash {
            min-width: 7px;
            height: 2px;
            background: #d9d9d9;
            border-radius: 5px;
        }

        input {
            width: 100%;
            outline: 0;
            border-width: 0;
            height: 40px;
            padding: 0 10px;
            background: rgba(0, 54, 64, 0.05);
            border-radius: 10px;
            color: $dark-blue;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
}
