@use "abstracts/variables" as *;
@use "base/font" as *;

.custom-select {
  select {
    background-color: $background-color;
    border: none;
    cursor: pointer;
    color: $color-secondary;
    font-size: $fs-normal;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.weather-select {
  .mat-select-value-text {
    color: white;
  }

  .mat-select-arrow {
    color: white;
  }
}