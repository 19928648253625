@use 'abstracts/space';
@use 'abstracts/variables';
@use 'abstracts/mixins';



.action__btn {
  color: variables.$color-primary;
  &#{&}--menu,
  &.mat-stroked-button, &.mat-flat-button, &.mat-raised-button, &.mat-button {
    min-width: 129px;
    min-height: 40px;
    padding: 2px 16px;
    .mat-icon.arrow-down {
      margin-left: 4px;
      width: 12px !important;
    }
  }
  &#{&}--icon {
    margin: 0px 5px;
    background-color: rgb(variables.$color-primary, 0.1);
    // @include mixins.mat-icon-button(35);

  }
  &#{&}--icon-delete{
    margin: 0px 5px;
    color: variables.$color-danger;
    background-color: rgb(variables.$color-danger,0.1);
    // background-color: transparent;
    // &:hover{
    //   background-color: rgb(variables.$color-btn-delete,0.1);
    // }

  }
  &#{&}--icon-edit{
    margin: 0px 5px;
    color: variables.$color-primary;
    background-color: rgb(variables.$color-primary,0.1);
    // background-color: transparent;
    // &:hover{
    //   background-color: rgb(variables.$color-btn-edit,0.1);
    // }

  }

}
.table {
  padding: 1.5em;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 5px variables.$color-table-shadow;
  .table-custom {
    width: 100%;
    box-shadow: none;
    tr {
      &:nth-child(even) {
        background-color: #f6f6f9;
      }
      th,
      td {
        &:first-child {
          border-radius: 5px 0px 0px 5px;
        }
        &:last-child {
          border-radius: 0px 5px 5px 0px;
        }

        // .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
        //   opacity: 0.54 !important;
        //   transform: translateY(0px) !important;
        // }
      }
      th.mat-header-cell {
        border-bottom: none;
        background-color: #dedde8;
        text-align: center;
        color: #000;
        .mat-sort-header-container{
          justify-content: center;
        }
      }
      td {
        border-bottom-style: hidden;
        white-space: nowrap;
        text-align: center;
        height: 55px;
        color: #00000085;

        .active {
          @include mixins.status-label(variables.$text-color-active);
        }
        .disactive {
          @include mixins.status-label(variables.$text-color-inactive);
        }

        .completed {
          @include mixins.status-label(variables.$color-complete);
        }
        .incompleted {
          @include mixins.status-label(variables.$color-incomplete);
        }

        @media screen and (max-width: 600px) {
          .completed {
            &::before {
              top: 22%;
            }
          }
          .incomplete {
            &::before {
              top: 22%;
            }
          }
        }
      }

      .col-name{
        padding-left: 15px;
        text-align: left !important;
      }

      &:last-child {
        padding-bottom: 1em;
        .mat-cell {
          border-bottom: none;
        }
      }
    }
  }
  .mobile-label {
    display: none;
  }

  @media (max-width: 600px) {
    .table-custom tr {
      td {
        white-space: nowrap;
        width: 100%;
        justify-content: space-between;
        padding: 0;
        height: 60px;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          border-bottom: none;
        }
        &:last-child {
          & > * {
            margin-left: auto;
          }
        }
      }
      border-bottom: none;

      .col-name{
        padding-left: 0px;
      }
    }
    .mobile-label {
      text-align: start;
      width: 80px;
      display: inline-block;
      font-weight: bold;
    }
    .mat-header-row {
      display: none;
    }
    .mat-row {
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 8px 24px;
    }
  }
}
//end of table custom style
