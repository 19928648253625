@use "../abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/breakpoint" as *;

@mixin bank-name {
  font-weight: $fw-bold;
  color: $color-secondary;
}

.payment-bank-icon {
  width: 50px !important;
  height: 50px !important;

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: contain;
  }

  border-radius: 100%;
}

.mat-option.payment-select-option {
  color: $color-secondary;
  display: flex;
  gap: 10px;
  align-items: center;
  height: 70px !important;

  .mat-option-text {
    height: 70px;
    color: $color-secondary;
    display: flex;
    gap: 10px;
    align-items: center;
    .bank-name {
      @include bank-name();
    }
  }
}

mat-select.payment-form-select {
  mat-select-trigger {
    color: $color-secondary;
    display: flex;
    gap: 10px;
    align-items: center;
    // height: 70px !important;
    background-color: $background-color;
    padding: 12px 15px;
    border-radius: 15px;
  }
}

.form-payment {
  .mat-form-field-infix {
    /* padding: 0.5em 0; */
    border-top: 0 solid transparent !important;
  }
  .bank-name {
    @include bank-name();
  }
}

.list-bank-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .caption-md {
    //   color: $black;
    //   font-weight: $fw-bold;
    //   font-size: $fs-regular;
    // }
  }
  .bank-accounts {
    overflow-y: auto;
    // flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0px;

    .mat-list-item {
      cursor: pointer;
      height: 70px;
      // background-color: $color-light-gray;
    }
  }

  .mat-list.mat-list-base {
    cursor: default;
  }

  button.btn-create-payment {
    align-self: flex-end;
    height: 41px;
  }
}

.qr-pic {
  width: 350px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
}

/** Medium Screen **/
@include media-breakpoint-down(md) {
  .payment-container {
    app-payment-banks {
      width: 100% !important;
    }
  }
}

/** SM Screen **/

@include media-breakpoint-down(sm) {
  .payment-container {
    height: auto !important;
    app-payment-banks {
      width: 100% !important;
    }
  }

  .qr-pic {
    width: 100%;
  }
}

/** Large Screen **/
@include media-breakpoint-between(md, lg) {
  .payment-container {
    app-payment-banks {
      width: 250px !important;
    }
  }
}
