@use "base/font" as *;

.notification-menu {
  .notification-box-container {
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.164);
    .notification-title {
      padding-bottom: 8px;
      // backgrounrgb(141, 98, 98)ite;
      display: flex;
      justify-content: space-between;
      // height: 66px;
      align-items: center;
      font-size: $fs-medium;

      .mat-card-header-text {
        margin: 0 !important;
        .mat-card-title {
          margin-bottom: 0;
        }
      }
    }
  }

  &.mat-menu-panel {
    margin-top: 30px;
    background-color: white;
    border-radius: 12px;
    max-width: 100% !important;
    .mat-menu-content:not(:empty) {
      padding-top: unset;
      padding-bottom: unset;
    }
  }
  .notification-box {
    // background-color: white;
    width: 471px;
    height: 570px;
    overflow: auto;
    li {
      list-style-type: none;
    }
  }

  .notification-item {
    outline: 0;
    .profile-online {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      padding-left: 50px;

      .profile-image {
        display: flex;
        gap: 10px;
        width: 100%;

        .images {
          width: 50px;
          width: 50px;

          img {
            width: 100%;
            height: 93%;
            border-radius: 50%;
            background-size: cover;
          }
        }
      }
      .name-with-name {
        display: flex;
        gap: 10px;
      }

      &.online {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #006879;
          border-radius: 50%;
          position: absolute;
          right: 20px;
        }
      }
    }
  }
}
