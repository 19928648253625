@use "abstracts/variables" as *;

mat-icon.mat-icon {
  &.svg-rect {
    &.svg-fill {
      &.color-secondary {
        rect {
          fill: $color-secondary;
        }
      }

      &.color-black {
        rect {
          fill: #000000;
        }
      }
    }
  }

  &.svg-path {
    &.svg-fill {
      &.color-primary {
        path {
          fill: $color-primary;
        }
      }

      &.color-secondary {
        path {
          fill: $color-secondary;
        }
      }

      &.color-black {
        path {
          fill: #000000;
        }
      }

      &.color-white {
        path {
          fill: #ffffff;
        }
      }
    }

    &.svg-stroke {
      &.color-secondary {
        path {
          stroke: $color-secondary;
        }
      }

      &.color-black {
        path {
          stroke: #000000;
        }
      }

      &.color-primary {
        path {
          stroke: $color-primary;
        }
      }
    }
  }
}
