.app-content {
    height: 100%;
    padding: 0 20px 20px 0;
}

.return-header {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}