@use "base/colors" as *;
@use "base/font" as *;
@use "mixin/breakpoint" as *;

.box-wrapper {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(445px, 1fr));
}

button.icon-btn-action {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 24px;
  height: 24px;
  line-height: normal;
}

button.favorite {
  position: absolute;
  right: 10px;
  top: 10px;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  .product-post-container {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
  }
}

// style announcement %% my-product-buy-list page //

.title-short-by {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  h4.mat-h4 {
    margin-bottom: 0;
  }

  .list {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
}

.content-shot-by {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    line-height: 3;
    color: $dark-blue;
    font-weight: $fw-normal;
    font-size: $fs-normal;
  }
}

.polygon-img {
  img {
    position: absolute;
    top: 11px;
    right: 15px;
  }
}

.card-container {
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  background: #ffffff;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-action {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .content-buttom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-left {
      .product-item-info {
        grid-gap: 5px 25px;
      }
    }

    .content-right {
      padding-top: 12px;

      .product-img img {
        margin-top: 10px;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 25px;
      }
    }
  }
}

.status {
  display: inline-flex;
  align-items: center;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &.pending {
    color: $color-pending;

    &::before {
      background-color: $color-pending;
    }
  }

  &.found {
    color: $color-success;

    &::before {
      background-color: $color-success;
    }
  }
}

.main-search {
  display: flex;
  width: 100%;
  // padding: 8px 16px;
  align-items: center;
  grid-gap: 24px;
  gap: 10px;
  background: #ffffff;
  border-radius: 10px;

  .situation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border: 1px solid $light-green;
    border-radius: 8px;
    padding: 5px 15px;
    margin: 10px 0 10px 10px;
    max-width: 330px;
  }

  .filter-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border: 1px solid $light-green;
    border-radius: 8px;
    padding: 6px 0;
    margin: 10px;
    max-width: 330px;
    position: relative;

    .clear-button {
      position: absolute;
      right: 5px;
    }
  }
}

.announcement-container,
.market-demand-container {
  .box-wrapper {
    overflow: auto;
  }

  .images-container {
    overflow: auto;
    display: flex;
    margin-bottom: 10px;

    img {
      min-width: 52px;
      min-height: 52px;
      object-fit: cover;
      border: 1px solid rgba(74, 98, 104, 0.1);
    }

    .more-images {
      width: 52px;
      height: 52px;
      min-width: 52px;
      min-height: 52px;
      display: grid;
      place-content: center;
      font-size: medium;
      color: rgba(0, 0, 0, 0.3);
      background-color: rgba(217, 217, 217, 0.4);
      border-radius: 10px;
    }
  }
}

.shop-profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #f4f7ff;
}

.custom-textarea {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 8px;

  &:focus-visible {
    outline: none !important;
  }
}

/** Medium Breakpoint **/

@include media-breakpoint-down(md) {
  .card-container {
    padding: 4px 12px;
    border-radius: 10px;
  }
  .box-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .shop-profile {
    width: 30px;
    height: 30px;
  }
}

/** Small Breakpoint **/
@include media-breakpoint-down(xs) {
  .content-main .aside {
    // min-width: 90px !important;
  }
}
