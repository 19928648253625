@use "base/colors" as *;
@use "base/font" as *;
@use "abstracts/variables" as *;
@use "mixin/breakpoint" as *;

$imageSize: 120px;

.shop-detail {
  .top {
    .content-container {
      .content {
        background: #ffffff;
        border-radius: 25px 25px 10px 10px;

        img.cover-photo {
          width: 100%;
          cursor: pointer;
          height: 246px;
          object-fit: cover;
          border-radius: 10px 10px 0px 0px;
        }

        img.profile-img {
          cursor: pointer;
          width: $imageSize;
          height: $imageSize;
          border-radius: 50%;
          position: absolute;
          top: -70px;
          border: 5px solid #ffffff;
          object-fit: cover;
          @include media-breakpoint-down(sm) {
            top: -70px;
            width: 100px;
            height: 100px;
          }
        }

        img.default-profile-img {
          cursor: auto;
        }

        .profile {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 20px 32px;
          position: relative;
          @include media-breakpoint-down(sm) {
            padding: 20px 12px;
          }

          .profile-summary {
            margin-left: $imageSize + 24px; // calculate image width + gap
            margin-top: -15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            height: 100px;
            @media screen and (max-width: map-get($map: $grid-breakpoints, $key: "md")) {
              margin-left: 12px;
              margin-top: 12px;
              display: flex;
              justify-content: space-between;
              flex: 1;
              height: auto;
              flex-direction: column;
              grid-gap: 18px;
              gap: 18px;
            }

            .shop-name {
              font-weight: $fw-bold;
              font-size: $fs-regular;
            }

            .profile-action {
              display: flex;
              align-items: center;
              gap: 10px;
              // height: 41px;
              flex-wrap: wrap;

              button.more-horiz {
                width: 35px;
                background-color: rgba(0, 54, 64, 0.1);
              }

              button {
                height: 41px;
              }
            }

            .separator {
              color: $color-form-underline;
            }
          }
        }

        .profile-detail {
          .value {
            line-height: 1.7;
          }
          padding: 0 20px 20px 20px;
          .border-right {
            @media screen and (max-width: map-get($map: $grid-breakpoints, $key: "sm")) {
              border-right: none !important;
            }
          }

          table {
            width: 100%;

            th {
              display: flex;
              align-items: center;
              width: 35px;
              height: 25px;
              color: $grey;
            }

            td {
              height: auto;
              width: 100%;
              div {
                padding-top: 4px;
                font-size: $fs-normal;
                color: #000000;
              }
            }

            .caption-md {
              margin: 3px 0 0 7px;
            }
          }
          .border-right {
            border-right: 1px solid $color-form-underline;
          }
        }
      }
    }

    .contact-name {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: $fs-normal;

      .no-link,
      .general-link {
        color: $light-green;
      }
    }
  }

  .content-main {
    height: calc(100% - 135px);
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    // position: relative;

    .content-body {
      height: auto;
      position: relative;

      .content-body__header {
        height: 29px;
      }

      .emulated-flex-gap > * {
        margin: var(--gap) 0 0 var(--gap);
      }

      .emulated-flex-gap {
        --gap: 12px;
        // margin-top: 16px;
        overflow-y: auto;
        width: 100%;
        // height: calc(100% - 10px);
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0 0 0 calc(-1 * var(--gap));
        width: calc(100% + var(--gap));
        &.small-product-container {
          @media screen and (min-width: map-get($map: $grid-breakpoints, $key: "md")) {
            height: auto;
          }
        }
        .product-item-card {
          width: 182px;
          @include media-breakpoint-down(md) {
            width: 150px;
            min-width: 140px;
          }
          .image-post {
            @include media-breakpoint-down(md) {
              height: 77px;
            }
          }
        }
      }

      .product-container {
        overflow-y: auto;
        width: 100%;
        max-height: calc(100% - 0px);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(174px, 180px));
        grid-gap: 18px;
        justify-content: center;
        padding: 18px 0px;
        background-color: white;
        border-radius: 10px;

        .product-item-card {
          max-width: 240px;
          -webkit-box-shadow: 0.5px 0.5px 10px 3px #d2dcfd;
          -moz-box-shadow: 0.5px 0.5px 10px 3px #d2dcfd;
          box-shadow: 0.5px 0.5px 10px 2px #d2dcfd;
          border-radius: 10px;
        }
      }

      .empty {
        height: calc(100% - 28px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        div {
          font-size: $fs-regular;
          font-weight: $fw-bold;
          color: $color-primary;
        }
      }
    }

    .aside {
      // width: 253px;
      max-height: 100%;
      @include media-breakpoint-down(md) {
        position: sticky;
        top: 0;
        right: 0;
        z-index: 10000;
      }
    }
  }
}

.shops {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 12px;
  justify-content: center;
  // position: relative;
  // height: 100%;

  .card-img {
    cursor: pointer;
    background: #ffffff;
    // max-width: 325px;
    // height: 288px;
    height: 166px;
    height: auto;
    border-radius: 10px;
    padding: 8px;

    .sell-market {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header {
        display: flex;
        flex-direction: column;

        .short-info {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .mat-divider.mat-divider-vertical {
            margin: 5px 0;
          }
        }
      }

      .profile-market {
        display: flex;
        align-items: center;
        gap: 10px;

        .profile img {
          cursor: pointer;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          border: 1px solid rgba(74, 98, 104, 0.1);
        }
      }

      button.btn {
        cursor: pointer;
        background: none;
        border: none;
      }
    }
  }
}

/** Medium Breakpoint **/

@include media-breakpoint-down(md) {
  .container-body {
    height: calc(100% - 80px) !important;
  }
  .shops {
    grid-template-columns: repeat(auto-fit, minmax(100%, 0fr));
    .contain-main {
      gap: 8px;
    }
  }
}
