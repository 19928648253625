@use "base/font" as *;
@use "base/colors" as *;
@use "abstracts/variables" as *;

.content-scroll {
  overflow-x: hidden;
  .tab-container {
    max-width: 400px;
  }
  .hidden {
    display: none !important;
  }
  .containers {
    margin-top: 15px;
  }

  .content-right-bottom {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;

    .content-bottom {
      padding: 15px 20px;
      background: #ffffff;
      border-radius: 15px;
      margin-bottom: 10px;

      .profile-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        app-button-send-message {
          display: flex;
          align-items: center;
        }

        .profile {
          display: flex;
          cursor: pointer;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 50%;
          }

          .shop-name {
            margin: 0;
          }
        }
      }
    }
  }
}
