@use "../base/colors" as *;

$text-colors: (
    "primary": $dark-green,
    "secondary": $light-green,
    "warn": $color_warn,
    "grey": $grey,
    "secondary-grey": rgba($grey, 0.4),
    "white": #FFFFFF,
    "red": $color-red
);

@mixin text_color($color, $important: false) {
    @if $important {
        color: $color !important;
    }

    @else {
        color: $color;
    }
}

/* text color */
@each $name,
$color in $text-colors {
    .text-#{$name} {
        @include text_color($color);
    }

    .\!text-#{$name} {
        @include text_color($color, true);
    }
}