$pixels: 500;

@mixin width($i, $important: false) {
    @if $important {
        width: $i * 1px !important;
    }

    @else {
        width: $i * 1px;
    }
}

@mixin height($i, $important: false) {
    @if $important {
        height: $i * 1px !important;
    }

    @else {
        height: $i * 1px;
    }
}

@for $i from 0 to $pixels {

    .w-#{$i} {
        @include width($i);
    }

    .\!w-#{$i} {
        @include width($i, true);
    }

    .h-#{$i} {
        @include height($i);
    }

    .\!h-#{$i} {
        @include height($i, true);
    }
}

.w-full {
    width: 100%;
}

.\!w-full {
    width: 100% !important;
}

.h-full {
    height: 100%;
}

.\!h-full {
    height: 100% !important;
}