@use "base/font" as *;
@use "base/colors" as *;
@use "abstracts/variables" as *;
@use "mixin/breakpoint" as *;

.containers {
  .content-dialog {
    .content-left {
      .image {
        margin-bottom: 10px;
        width: 100%;
        height: 500px;
        @include media-breakpoint-down(md) {
          height: 200px;
        }

        .slider {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          border-radius: 10px;
        }

        .p-img,
        .default-img {
          width: 100%;
          height: 545px;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
          @include media-breakpoint-down(md) {
            height: 200px;
          }
        }

        .slide {
          position: absolute;
        }

        .arrow {
          position: absolute;
          top: 50%;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .arrow.prev {
          left: 20px;
          font-size: $fs-medium;

          &:hover {
            color: $light-grey-bg;
          }
        }

        .arrow.next {
          right: 20px;
          font-size: $fs-medium;
          text-align: right;

          &:hover {
            color: $light-grey-bg;
          }
        }

        .nav {
          display: block;
          position: absolute;
          bottom: -10px;
          width: 100%;
          margin: auto;
          text-align: center;
        }

        .dots {
          padding-left: 0;
        }

        .nav .dot,
        .nav .dot span {
          display: inline-block;
          cursor: pointer;
        }

        .dots .dot {
          position: relative;
          width: 12px;
          height: 12px;
        }

        .dots .dot span {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 6px;
          height: 6px;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          background: $light-grey-bg;
          border: 1px solid transparent;
          outline: none;
          box-shadow: none;
          border-radius: 50%;
        }

        .dots .dot span.active {
          background-color: $color-secondary;
        }
      }
    }

    .content-right {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title-and-button {
        display: flex;
        justify-content: space-between;
      }
      .content-right-save-date {
        width: 50%;
      }
      .save-and-date {
        display: flex;
        gap: 3%;

        .border-left {
          border-left: 1px solid rgba(74, 98, 104, 0.1);
          padding-left: 10px;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      border-left: 1px solid #4a62680d;

      table {
        tr > th.text-icon {
          align-items: center;
          height: 30px;
          color: $grey;
        }

        td.tels {
          width: 50%;
        }
      }
    }
  }
}

.product-item-info {
  width: 100%;
  display: grid;
  grid: auto/auto auto;
  gap: 5px;
  margin-top: 10px;
  line-height: 25px;
}

.text-icon {
  display: flex;
  align-items: center;
  gap: 5px;

  .price-number {
    font-weight: 700;
    color: $color-secondary;
    line-height: 24px;
  }
}

/** Medium Breakpoint **/

@include media-breakpoint-down(md) {
  .containers .content-dialog .content-right {
    .content-right-save-date {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .title-and-button {
      display: flex;
      /* justify-content: space-between; */
      flex-direction: column;
      gap: 8px;
    }
  }
}
