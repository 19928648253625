@use "abstracts/variables" as *;
@use "base/colors" as *;
.my-otp-input,
.my-otp-input-invalid {
  input {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.my-otp-input {
  input {
    border: 1px solid $color-tertiary !important;
  }
}

.my-otp-input-invalid {
  input {
    border: 1px solid $color-danger !important;
  }
}

.container-form {
  .back-page {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    .mat-h3 {
      margin: 0;
    }
  }

  .flex-row {
    width: 100%;
    display: flex;
    gap: 50px;
    line-height: 3;
  }
  .mat-h4 {
    margin-top: 20px;
    margin-bottom: -10px;
  }
}

.input-column {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  flex: 1;

  label {
    font-size: 14px;
    color: $grey;
  }

  .input-group {
    width: 100%;
    border-bottom: 1px solid $input-border-color;
    display: flex;
    gap: 20px;
    font-size: 14px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.with-select {
      input {
        width: 100%;
      }

      .mat-select {
        width: 30%;
        text-align: right;
      }

      .custom-mat-select  {
        width: 50%;
      }
    }

    input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      color: black;
    }
  }
}
