@use "base/colors" as *;

$color-primary: $dark-green;
$color-secondary: $light-green;
$color-tertiary: $blue-grey;
$color-danger: $red;
$color-incomplete: $yellow;
$color-btn-edit: $yellow;
$color-complete: $green;
$color-btn-delete: $red;
$color-gray: $grey;
$text-color-gray: $grey;
$text-color-active: $green;
$text-color-inactive: $grey;
$color-table-shadow: rgb($light-blue, 0.1);
$color-light-gray: $light-grey;
$input-border-color: $medium-grey;
$background-color: $light-grey-bg;
$color-form-underline: rgba(74, 98, 104, 0.1);
$bg-green-glass: $dark-green-glass;
$bg-green: $green;
$default-background: #EFF3F6;

$disabled-btn-color: $light-grey;
$disabled-text-color: $medium-grey;
$color-medium-gray: $medium-grey;

$line-color: $light-grey-line;

$menu-width: 320px;
$menu-height: 80px;
$store-header-height: 70px;
$menu-width-v2: 270px;

$grid-gutter-width: 1.5rem !default;

/* grid-breakpoints bootstrap docs*/
$grid-breakpoints: (
    /* Extra small screen / phone */ "xs": 0,
    /* Small screen / phone */ "sm": 576px,
    /* Medium screen / tablet */ "md": 768px,
    /* Large Laptop */ "lg": 992px,
    /* Extra large screen / wide desktop */ "xl": 1200px,
    /* Rarely use*/ "xxl": 1400px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    /*xl: 1140px*/ xl: 1400px,
    /*xxl: 1320px*/ xxl: 1400px
) !default;
// scss-docs-end container-max-widths

// Container padding

$container-padding-x: $grid-gutter-width !default;

/* scss edges */
$edges: (
    "t": top,
    "r": right,
    "b": bottom,
    "l": left
) !default;

/* scss edges */
$edges: (
    "t": top,
    "r": right,
    "b": bottom,
    "l": left
) !default;

$edge-axis: x, y !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-container-classes: true !default;
