@use "abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;
@use "mixin/flex" as *;
@use "mixin/breakpoint" as *;

$border-color: rgba(74, 98, 104, 0.05);
$badge-color: #ff0f00;
$border-color-order-history: rgba(151, 151, 151, 0.1);
$key-color: rgba(117, 117, 117, 0.9);
$tb-header-color: rgba(0, 0, 0, 0.5);

@mixin imageSection() {
    .img-section {
        width: 62px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
            border: 1px solid rgba(74, 98, 104, 0.1);
        }
    }
}

@mixin flexCenter() {
    @include flexbox;
    @include align-items(center);
    gap: 8px;
}

@mixin total {
    .total {
        padding-right: 20px;
        .caption-md {
            &.value {
                font-weight: $fw-bold;
                color: $dark-blue;
            }
        }
    }
}

.product-reservation {
    .mat-divider {
        border-top-color: $border-color;
    }
    .mat-badge-content {
        background: $badge-color;
    }
    .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -5.641px;
    }
    .mat-badge-small.mat-badge-above .mat-badge-content {
        top: -5.141px;
    }
    .mat-horizontal-stepper-header-container {
        justify-content: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        width: 50%;
        margin: auto;
        .mat-horizontal-stepper-header {
            height: 55px;
            pointer-events: none !important;
            &.cdk-program-focused {
                background: transparent;
            }
            .mat-step-label {
                .mat-step-text-label {
                    font-weight: $fw-bold;
                }
                &.mat-step-label-selected {
                    color: $dark-blue;
                }
            }
        }
    }

    .mat-horizontal-content-container {
        padding: 24px 20px 0px 0;
    }

    .product-container {
        --gap: 16px;
        overflow-y: auto;
        height: 560px;
        width: 100%;
        /* margin: calc(0px*var(--gap)) 0 0 calc(-1px * var(--gap)); */
        width: calc(100% + var(--gap));
        padding-right: 10px;
        // position: relative;
        .empty {
            height: calc(100% - 70px);
            .info {
                .no-result {
                    font-size: $fs-regular;
                }
            }
        }
    }

    .loading-section {
        position: relative;
    }

    .product-items-container {
        padding-left: 16px;
    }

    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;

        .search-container {
            --height: 40px;
            --max-width: 320px;
            display: flex;
            align-items: center;
            gap: 8px;
            .search-box__customize {
                max-width: var(--max-width);
                height: var(--height);
                border-radius: 12px;
                .search {
                    width: var(--max-width);
                    .mat-icon-button {
                        width: auto;
                        height: auto;
                    }
                }
            }
            .btn-md-1 {
                height: var(--height);
            }
        }
    }

    .product-section {
        display: flex;
        align-items: center;
        gap: 12px;

        .img-section {
            width: 64px;
            height: 64px;
            min-width: 64px;
            min-height: 64px;
        }

        .img-section-sm {
            width: 52px;
            height: 52px;
            min-width: 52px;
        }

        .img-section,
        .img-section-sm {
            img {
                width: 100%;
                height: 100%;
                border-radius: 15px;
                object-fit: cover;
                border: 1px solid rgba(74, 98, 104, 0.1);
            }
        }
    }

    .product-expansion-container {
        .mat-expansion-panel {
            &-header {
                height: auto;
                padding: 12px 20px 12px 0px;
            }
            &-spacing {
                margin: 0;
            }
        }

        .mat-expansion-indicator::after {
            color: $dark-blue;
        }

        .mat-expansion-panel:not([class*="mat-elevation-z"]) {
            box-shadow: none;
            border-bottom: 1px solid $border-color;
            padding-bottom: 8px;
        }

        .mat-expansion-panel-body {
            padding: 15px 26px;
            border-radius: 10px;
            background: rgba(74, 98, 104, 0.03);
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .total_price {
                display: flex;
                gap: 8px;
            }
        }
    }

    .form-control {
        .mat-form-field-infix {
            display: flex;
            border-top: 0px;
            color: $dark-blue;
            .custom-select {
                max-width: 65px;
            }
            .mat-select-arrow,
            .mat-select-value {
                color: $dark-blue;
            }
        }
        .mat-form-field-flex {
            gap: 8px;
        }
    }

    .stepper-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navigation-container {
        display: flex;
        /* width: auto; */
        align-items: center;
        gap: 8px;
        padding-bottom: 8px;

        .btn-back {
            display: flex;
            align-items: center;
            width: auto;

            .mat-button-wrapper {
                gap: 0;
            }

            &.mat-flat-button {
                padding: 0;
            }
        }
        .badge-custom {
            border-radius: 50%;
            background: $badge-color;
            width: 16px;
            height: 16px;
            line-height: 16px;
            font-size: 10px;
            color: #fff;
            font-weight: 600;
            text-align: center;
            padding: 1px;
            padding-left: 0;
        }
    }

    .cart-section,
    .product-invoice,
    .product-item-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &.gap-invoice {
            gap: 14px;
        }
        .product-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;

            &.border-bottom {
                border-bottom: 1px solid $border-color;
            }

            .action-section {
                --gap-x: 16px;
                display: flex;
                gap: var(--gap-x);
                &.border-left {
                    padding-left: var(--gap-x);
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                }
                .caption-md {
                    font-weight: $fw-bold;
                    color: $dark-blue;
                }
            }
        }
        .app-loading {
            width: 100%;
            left: 0;
            bottom: 0;
            height: 102%;
        }
    }

    .scroll-section,
    .recheck-product-reservation-content {
        --gap: 16px;
        overflow-y: auto;
        max-height: 70vh;
        padding-right: var(--gap);
        width: calc(100% + var(--gap));
    }

    .total_cart {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .caption-md:last-child {
            color: $dark-blue;
            font-weight: $fw-bold;
        }

        &.border {
            padding: 12px 0;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
        }
    }

    .border-right {
        border-right: 1px solid $border-color;
    }

    .reservation-info {
        .product-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0px;
        }

        .image-section {
            width: 51px;
            height: 47px;
        }

        .product-invoice {
            padding-left: 27px;
            .mat-divider {
                margin-bottom: 0;
            }
        }

        .delivery-info {
            padding-right: 39px;
        }
    }

    .caption-md {
        &.value {
            color: $dark-blue;
            font-weight: $fw-bold;
            &.--without-bold {
                font-weight: normal;
            }
        }
        &.align-right {
            text-align: right;
        }
    }

    .caption-lg {
        &.header {
            color: #000000;
            font-weight: $fw-bold;
        }
    }

    .app-loading {
        z-index: 1050;
        position: absolute;
        height: 104%;
        width: 115%;
        top: -11px;
        left: -10%;
        right: 0;
        bottom: -8px;
    }
}

.order-history {
    .content {
        overflow: auto;
        padding-right: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 65vh;
    }

    @include imageSection();

    .caption-md {
        &.value {
            color: $dark-blue;
        }
    }

    .order-list {
        --gap-inside: 4px;
        --gap-outside: 8px;
        @include flexbox;
        @include flex-direction(column);
        border-bottom: 1px solid $border-color-order-history;
        padding-bottom: 12px;
        gap: 12px;
        .short-info {
            @include flexbox;
            @include flex-direction(column);
            gap: var(--gap-inside);
            padding-bottom: var(--gap-outside);

            .btn-group {
                padding-left: var(--gap-outside);
                border-left: 2px solid $border-color-order-history;
            }
        }
        .product-items-container {
            @include flexbox;
            gap: 8px;
            @include media-breakpoint-down(md) {
                overflow-x: auto;
            }

            .product-item {
                @include flexbox;
                @include flex-direction(column);
                gap: var(--gap-inside);

                .img-section {
                    width: 62px;
                    height: 60px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        // border: 1px solid rgba(74, 98, 104, 0.1);
                    }
                    &.box {
                        color: white;
                        border-radius: 8px;
                        object-fit: cover;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: $fs-normal;
                        color: rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        background: rgba(217, 217, 217, 0.4);
                    }
                }
                .caption-md {
                    width: 62px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .empty-list {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
    }
}

.order-history-info {
    @include imageSection();
    .product-item-table {
        width: 100%;
        font-size: $fs-normal;
        .mat-table {
            border-spacing: 8px;
        }
        .product-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            .img-section {
                width: 40px;
                height: 40px;
                min-width: 40px;
            }
            .caption-md {
                padding-right: 17px;
            }
        }
        tr.mat-header-row {
            margin-bottom: 8px;
            height: auto;
        }
        th {
            font-size: $fs-normal;
        }
        th.mat-header-cell:first-of-type,
        td.mat-cell:first-of-type,
        td.mat-footer-cell:first-of-type {
            padding-left: 0;
            min-width: 55px;
        }
        th.mat-header-cell,
        td.mat-cell {
            border-bottom: none;
            &:not(:nth-child(0), :nth-child(1)) {
                min-width: 92px;
            }
        }
        tbody {
            padding: 8px 0;
        }
    }

    .product-summary {
        padding: 0 20px 12px 0px;
        @include media-breakpoint-down(md) {
            overflow-x: auto;
        }
    }

    .ml-auto {
        margin-left: auto;
    }

    .total {
        padding-right: 20px;
        .caption-md {
            &.value {
                font-weight: $fw-bold;
                color: $dark-blue;
            }
        }
    }

    .order-tracking {
        .mat-step-icon {
            height: 40px;
            width: 40px;
            .mat-icon {
                --icon-size: 24px;
                font-size: var(--icon-size);
                height: var(--icon-size);
                width: var(--icon-size);
            }
        }

        .mat-step:last-child {
            .mat-vertical-content-container {
                display: none;
            }
        }

        .mat-stepper-vertical-line::before {
            border-left-color: #21bf73;
        }

        .mat-vertical-content-container {
            margin-left: 20px;
            padding-top: 14px;
        }

        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
        .mat-vertical-stepper-header {
            padding: 18px 0px;
        }

        .mat-step-header .mat-step-icon,
        .mat-step-header .mat-step-icon-selected,
        .mat-step-header .mat-step-icon-state-done,
        .mat-step-header .mat-step-icon-state-edit {
            background-color: #21bf73;
            color: white;
        }

        .mat-step-header.cdk-keyboard-focused,
        .mat-step-header.cdk-program-focused,
        .mat-step-header:hover:not([aria-disabled]),
        .mat-step-header:hover[aria-disabled="false"] {
            background-color: transparent;
            cursor: default;
        }
    }

    .payment-type {
        @include flexCenter;
        gap: 8px;
        .img-section {
            width: 24px;
            height: 23.25px;
        }
    }

    .profile {
        @include flexCenter;
        .img-section {
            width: 24px;
            height: 24px;
            min-width: 24px;
            img {
                border-radius: 50%;
            }
        }
    }

    .txt-ellipsis {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .caption-md {
        &.color--primary {
            color: $dark-blue;
        }
    }
}

.payment-dialog {
    @include imageSection();
    @include total();
    .product-summary {
        @include flexbox;
        @include flex-direction(column);
        gap: 12px;
        .product {
            border-bottom: none;
            gap: 12px;
            .product-section {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;

                .img-section {
                    width: 60px;
                    height: 55px;
                }
                .description {
                    width: 100%;
                }
            }
        }
    }
    .total {
        padding-right: 0;
    }
}

/** Medium Breakpoint md **/

@include media-breakpoint-down(md) {
    .order-history .order-list .product-items-container .product-item {
        .caption-md {
            width: 50px;
        }

        .img-section {
            width: 50px;
            height: 50px;
        }
    }

    .product-reservation {
        .mat-horizontal-stepper-header-container {
            width: 60%;
        }
    }
}

/** Small Screen Section **/

@include media-breakpoint-down(sm) {
    .product-reservation {
        .mat-horizontal-stepper-header-container {
            width: 100%;

            .mat-horizontal-stepper-header {
                padding: 0 18px;
            }
        }

        .mat-horizontal-content-container {
            padding: 0px 18px 0px 0;
        }

        .product-expansion-container {
            .mat-expansion-panel-header {
                padding: 12px 12px 12px 0px;
            }

            .mat-expansion-panel-body {
                padding: 8px 8px;
            }

            .footer {
                align-items: start;
                flex-direction: column;
                gap: 8px;
            }
        }

        .filter {
            display: flex;
            justify-content: flex-start;
            align-items: start;
            padding-bottom: 8px;
            flex-direction: column;
            gap: 8px;
        }

        .search-container {
            --max-width: auto !important;
        }

        .product-section {
            .img-section {
                --img-size: 50px;
                width: var(--img-size);
                height: var(--img-size);
                min-width: var(--img-size);
                min-height: var(--img-size);
            }

            .caption-lg {
                font-size: 14px;
            }
        }

        .reservation-info {
            .delivery-info {
                padding-right: 0;
            }

            .product-invoice {
                padding-left: 0px;
            }
        }

        .border-right {
            border-right: none;
        }

        .recheck-product-reservation-content {
            max-height: 60vh;
        }
    }
}

/** Extra Small Screen **/

@include media-breakpoint-down(xs) {
    .product-reservation {
        .product-container {
            --gap: 12px;
            height: 60vh;
        }

        .scroll-section {
            --gap: 8px;
            max-height: 65vh;
        }

        // .recheck-product-reservation-content {
        //     max-height: 55vh;
        // }
    }

    .message-container .message-box .reply-container .reply-container-wrapper button {
        min-height: 32px !important;
        min-width: 32px !important;

        &.mat-icon-button {
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
    }

    .chat-header .right-side {
        gap: 8px !important;
    }
}
