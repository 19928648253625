@use "base/font" as *;
@use "base/colors" as *;

.page-sidenav-title {
  font-size: $fs-normal;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.page-header {
  border-bottom: 1px solid rgba(74, 98, 104, 0.05);
  display: flex;
  flex-direction: column;
  gap: 12px;

  .page-header_title {
    font-size: $fs-medium;
    font-weight: $fw-bold;
    height: 33px;
    margin-bottom: 10px;
  }
}

.page-action {
  height: 65px;
  max-width: 475px;
  margin-bottom: 4px;
  // margin-top: 10px;
}

// this is a share layout style
// in order management using this layout, layout-01
.layout-01 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;

  .col1 {
    flex: 1 1 55%;
  }

  .col2 {
    flex: 1 1 45%;
  }
}

.page-layout-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(74, 98, 104, 0.05);
    height: 63px;
    padding: 20px;
    margin-bottom: 20px;

    .page-title {
      flex: 1 1 auto;
    }
  }

  .page-header-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .page-title {
      font-size: $fs-regular;
      font-weight: $fw-bold;
      height: 29px;

      &.link-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        mat-icon.mat-icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .page-content {
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    overflow-y: auto;
  }
}

@media (max-width: 1280px) {
  .layout-01 {
    flex-direction: column;
  }
}
