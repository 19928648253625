@use "abstracts/variables" as *;
@use "abstracts/dimensions" as *;
@use "mixin/breakpoint" as *;

.store-info-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    .containers {
        width: 100%;
        background-color: white;
        border-radius: $default-card-radius;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 20px;
        position: relative;
        gap: 10px;

        .image-container {
            width: 100%;
            height: 250px;
            position: relative;
            overflow: hidden;
            border-top-left-radius: $default-card-radius;
            border-top-right-radius: $default-card-radius;
            overflow: hidden;
            cursor: pointer;

            .cover-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .upload-img-bg {
                position: absolute;
                right: 16px;
                top: 16px;
                // background: rgba(0, 0, 0, 0.25);
                display: flex;
                gap: 6px;
            }
        }

        .profile-and-action {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 0 15px;
            gap: 30px;
            justify-content: flex-start;
            align-items: center;
            z-index: 2;
            .profile-img-box {
                width: 100px;
                height: 100px;
                margin-top: -50px;
                margin-left: 25px;
                // overflow: hidden;
                position: relative;

                .profile-img {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .default-profile-img {
                    cursor: auto;
                }
            }

            .shop-name {
                font-size: 1em;
            }

            .spacer {
                flex: 1;
            }

            .action {
                ::ng-deep mat-icon path {
                    fill: white;
                }
            }
        }

        .input-container {
            width: 100%;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            .block-title {
                margin: 0;
            }

            .input-block {
                width: 100%;
                display: grid;
                grid-gap: 25px;
                grid-template-columns: repeat(4, 1fr);
                margin-bottom: 20px;

                .input-with-icon {
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 1px solid #edf0f0;

                    label {
                        color: $color-gray;
                        font-size: 14px;
                    }

                    .input-box {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        background: none;

                        .input {
                            flex: 1;
                            border: none;
                            outline: none;
                            background: none;
                            padding: 8px 5px;
                        }

                        .chip-item {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            background-color: $background-color;

                            .mat-chip-remove {
                                display: flex !important;
                                flex-direction: column !important;
                                justify-content: center !important;
                                align-items: center !important;
                                width: fit-content !important;
                                height: fit-content !important;
                                color: red !important;
                            }
                        }

                        .chip-text {
                            margin: 0;
                        }
                    }

                    &.span-3-col {
                        grid-column: 1 / span 3;
                    }
                }
            }
        }

        mat-divider {
            width: 100%;
        }

        .map-container {
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (max-width: #{$store-mode-breakpoint + 100px}) {
    .input-block {
        grid-template-columns: repeat(auto-fit, minmax(371px, 1fr)) !important;
    }
}

.upload-img-profile {
    position: absolute !important;
    top: 0px;
    right: 0px;
}

.default-cover {
    cursor: auto;
    background: #f0f0f0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    img {
        height: 190px;
    }
}

/** Small Screen **/

@include media-breakpoint-down(sm) {
    .store-info-page .containers {
        .image-container {
            height: 146px;
        }

        .profile-and-action {
            padding: 0px 8px;
            flex-wrap: wrap;
            .action {
                margin-left: auto;
            }
        }
    }

    .store-info-page .containers .profile-and-action .profile-img-box {
        width: 68px;
        height: 68px;
        margin-top: -50px;
        margin-left: 0px;
        overflow: hidden;
        position: relative;
    }
}
