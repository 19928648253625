.profile-upload-card {
    padding: unset !important;

    .header {
        padding: 16px;
        border-bottom: 1px solid #dddddd;

        .mat-card-title {
            margin-bottom: unset !important;
        }

        .more {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }


    .my-card-content {
        width: 100%;
        overflow-y: auto;
        max-height: 500px;
        padding: 16px;
        display: flex;

        .photo-section {
            width: 100%;
            height: 300px;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            position: relative;

            .photo {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .photo-placeholder {
                    display: flex;
                    align-items: center;
                    color: #8D8D8D !important;

                    .mat-icon {
                        margin-right: 5px;
                    }
                }

                .photo-inset {
                    border-radius: 50%;
                    height: 280px;
                    width: 280px;
                    object-fit: cover;
                }
            }
        }
    }

    .action {
        justify-content: end;
        padding: 16px;
    }
}

.cover-upload-card {
    padding: unset !important;

    .header {
        padding: 16px;
        border-bottom: 1px solid #dddddd;

        .mat-card-title {
            margin-bottom: unset !important;
        }

        .more {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }


    .my-card-content {
        width: 100%;
        overflow-y: auto;
        max-height: 500px;
        padding: 16px;
        display: flex;

        .photo-section {
            width: 100%;
            height: 190px;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            position: relative;

            .photo {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .photo-placeholder {
                    display: flex;
                    align-items: center;
                    color: #8D8D8D !important;

                    .mat-icon {
                        margin-right: 5px;
                    }
                }

                .photo-inset {
                    border-radius: 16px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .action {
        justify-content: end;
        padding: 16px;
    }
}

.upload-image-card-container {
    .img-container {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        border-radius: 10px;
        padding: 5px 0;
        display: -moz-box;
        display: -webkit-box;
        display: -moz-inline-box;
        display: -webkit-inline-box;

        .img-inset {
            height: 100px;
            width: 100px;
            position: relative;

            &:not(:last-child) {
                margin-right: 4px;
            }

            &.add-pic {
                display: flex;
                padding: 13px 7px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 4px;

                border-radius: 10px;
                border: 1px solid rgba(0, 54, 64, 0.10);
                background: #F6F6F6;
                cursor: pointer;

                .inside-icon {
                    border-radius: 4px;
                    width: 32px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                span.caption-sm {
                    height: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 170%;
                }
            }

            .img {
                border-radius: 10px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 1px solid rgba(74, 98, 104, 0.10);
                box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
            }

            .remove {
                position: absolute;
                bottom: 8px;
                right: 8px;

                button.mat-icon-button.icon-btn-remove {
                    width: 26px;
                    height: 26px;
                    line-height: normal;
                }
            }
        }
    }
}