// @use "abstracts/variables" as *;
@use "base/font" as *;
@use "base/colors" as *;

.search-box__customize {
    border-radius: 8px;
    border: 1px solid var(--black-10, rgba(44, 51, 62, 0.1));
    background: #ffffff;
    border-radius: 15px;
    padding: 8px 12px;

    height: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $fs-normal;

    .search {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .pre-icon {
            width: 24px;
            height: 24px;
        }

        &.action {
            border: none;
            width: auto;
            padding: 0 0 0 10px;
            margin: 0;
        }
    }

    input {
        width: 100%;
        outline: 0;
        border-width: 0;
        height: 30px;
        background-color: transparent;
    }

    input::placeholder {
        font-size: $fs-normal;
        color: $medium-grey;
    }
}
