$size: 50;

$font-weight: (
    "thin": 100,
    "extraLight": 200,
    "light": 300,
    "normal": 400,
    "medium": 500,
    "semibold": 600,
    "bold": 700,
    "black": 900
);

@for $i from 0 to $size {

    // class name: text-[0,1,...]
    .text-#{$i} {
        font-size: $i + px;
    }
}

@each $name,
$weight in $font-weight {

    .font-#{$name} {
        font-weight: $weight;
    }
}