@use "../base/colors";

.creation-form,
.detail-form {
    background-color: white;
    border-radius: 10px;
    padding: 20px;

    label {
        color: colors.$grey;
    }

    .mat-form-field {
        &.mat-form-field-disabled {
            .mat-form-field-underline {
                background-image: unset;
            }
        }
    }
}

.detail-form {
    .mat-form-field {
        input.mat-input-element {
            color: black;
        }
    }
}

.dialog-form {
    label {
        color: colors.$grey;
    }
}