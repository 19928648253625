// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "../abstracts/variables";
@import "../base/font";
@import "../base/colors";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-typography: mat.define-typography-config(
  $font-family: "Roboto, Khmer",
  $headline: mat.define-typography-level(20px, 32px, 700),
  $title: mat.define-typography-level(18px, 32px, 700),
  $subheading-2: mat.define-typography-level(16px, 28px, 700),
  $subheading-1: mat.define-typography-level(14px, 25px, 700),
  $body-2: mat.define-typography-level(14px, 25px, 500),
  $body-1: mat.define-typography-level(14px, 25px, 400),
  $caption: mat.define-typography-level(12px, 22px, 400)
);

@include mat.core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$primary-palette: (
  50: #e6ece7,   // Lightest - softened from previous palette
  100: #bbd0c0,  // Light
  200: #8eb395,  // Light-medium
  300: #619669,  // Medium
  400: #3d7a47,  // Dark-medium
  500: #1b552c,  // Base color (modified)
  600: #164d27,  // Slightly darker
  700: #114421,  // Even darker
  800: #0c3b1c,  // Darkest
  900: #072a13,  // Almost black
  A100: #6bff8a, // Accent light
  A200: #3aff5c, // Accent medium
  A400: #00ff2f, // Accent bright
  A700: #00e625 , // Accent deep
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$secondary-palette: (
  50: #e6f2ea,   // Lightest - soft green
  100: #bfe0c6,  // Light
  200: #94cea0,  // Light-medium
  300: #69bc7a,  // Medium
  400: #44ac58,  // Dark-medium
  500: #198A45,  // Base color (new input)
  600: #167c3d,  // Slightly darker
  700: #136e36,  // Even darker
  800: #0f602e,  // Darkest
  900: #0a4d1f,  // Almost black
  A100: #7dffb0, // Accent light
  A200: #4aff8a, // Accent medium
  A400: #17ff65, // Accent bright
  A700: #00fc4c,  // Accent deep
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$warn-palette: (
  50: #f7e4e4,
  100: #eabbbb,
  200: #dd8d8d,
  300: #cf5f5f,
  400: #c43d3d,
  500: #ba1b1b,
  600: #b31818,
  700: #ab1414,
  800: #a31010,
  900: #940808,
  A100: #ffc1c1,
  A200: #ff8e8e,
  A400: #ff5b5b,
  A700: #ff4141,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$admin-primary: mat.define-palette($primary-palette);
$admin-accent: mat.define-palette($secondary-palette);

// The warn palette is optional (defaults to red).
$admin-warn: mat.define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $admin-primary,
      accent: $admin-accent,
      warn: $admin-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-theme);

.mat-typography {
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 8px;
  }
}

.mat-flat-button {
  &.mat-edit {
    background-color: #fda40d;
    color: white;
  }
}

.mat-tab-labels {
  .mat-tab-label[role=tab] {
    opacity: 1;

    &.mat-tab-label-active {
      color: $light-green;

      .mat-tab-label-content {
        font-weight: bold;
      }
    }
  }
}

.mat-tab-label,
.mat-tab-link {
  color: $grey;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $light-green;
}
