@use "../base/colors" as *;

$background-colors: (
    "primary": $dark-blue,
    "white": #FFFFFF,
    "grey": $light-grey-bg,
    "red": $color-red
);

/* background color */
@each $name,
$color in $background-colors {

    .bg-#{$name} {
        background-color: $color;
    }
}