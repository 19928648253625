@use "mixin/breakpoint" as *;

.chip-status {
  border-radius: 13px;
  padding: 2px 12px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 28px;

  &.without-background {
    background: transparent !important;
  }

  &.align-right {
    padding: 2px 0;
    align-items: end;
  }

  &.align-left {
    padding: 2px 0;
    align-items: start;
  }

  &.PENDING {
    background: rgba(254, 191, 16, 0.1);
    color: #febf10;
  }

  &.PAID {
    background: rgba(0, 153, 51, 0.1);
    color: #009933;
  }

  &.CONFIRMED {
    background: rgba(64, 123, 255, 0.1);
    color: #407bff;
  }

  &.COD {
    background: rgba(126, 158, 33, 0.1);
    color: #7e9e21;
  }

  &.CANCELED {
    background: rgba(255, 15, 0, 0.1);
    color: #ff0f00;
  }

  &.REFUNDED {
    background: rgba(0, 153, 51, 0.1);
    color: #009933;
  }
}

/** Medium Breakpoint **/

@include media-breakpoint-down(md) {
  .chip-status {
    height: auto !important;
  }
}
