@use "abstracts/variables" as *;
@use "base/font" as *;

::ng-deep .custom-mat-menu {
    border-radius: 15px;
    margin-top: 5px;
    padding: 10px;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            justify-content: flex-start;
            color: $color-gray;
            font-size: $fs-normal;
            border-radius: 15px;
            line-height: 45px;
            min-width: 150px;
            height: 45px;

            mat-icon {
                color: $color-secondary;
                margin-right: 15px;

                &.svg-rect {
                    &.svg-fill {
                        rect {
                            fill: $color-secondary;
                        }
                    }
                }

                &.svg-path {
                    &.svg-fill {
                        path {
                            fill: $color-secondary;
                        }
                    }

                    &.svg-stroke {
                        path {
                            stroke: $color-secondary;
                        }
                    }
                }
            }

            &:hover {
                background: rgba(0, 104, 121, 0.05);
            }
        }

        .mat-menu-item {
            color: $color-secondary;
        }
    }
}

.separate {
    border: 1px solid rgba(74, 98, 104, 0.05);
    height: 35px;
    margin: 0 10px 0 0;
}

.shop-owner {
    display: flex;
    align-items: center;
}

.shop-owner-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
