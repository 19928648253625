.apple-redirect{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    
    .card{
        background: linear-gradient(90deg, #282B52, #5055A3);
        border-radius: 16px;
        padding: 2em;
        
        .logo-form {
            .logo {
                .image-logo-sm{
                    display: block !important;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                    img{
                        width: 150px;
                    }
                    h1{
                        margin: unset;
                    }
                }
            }
        }
    }
}