@use "abstracts/variables" as *;
@use "base/font" as *;
@use "abstracts/mixins" as *;
@use "mixin/breakpoint" as *;

.weather-board {
    position: relative;
    width: calc(100% - 36px);
    border-radius: 10px;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(93.59deg, #1c70ad 0%, #4a6268 100%);
    margin-bottom: 18px;

    .inner-board {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        filter: blur(7.5px);
        @include background-image-contain("/assets/imgs/bg-cloud.svg");
        top: 0;
        right: 0;
    }
    .content-left {
        width: 40%;
        .content-top {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .current-time {
                font-size: $fs-regular;
                font-weight: $fw-bold;
            }
        }

        .weather {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-size: $fs-regular;
            gap: 20px;

            img {
                width: 100px;
                height: 100px;
            }

            .weather-degree {
                font-size: 50px;
                margin: 15px 0;
            }

            div {
                font-weight: $fw-bold;
            }
        }
    }

    .vert-divider {
        width: 0px;
        border: 1px solid white;
        height: 313px;
    }

    .content-right {
        width: 50%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 20px;
    }
}

.weather-daily,
.weather-today {
    overflow-x: auto;
    min-height: 150px;

    .weather-card-container {
        display: flex;
        gap: 18px;
        height: 130px;

        .weather-card {
            cursor: pointer;
            min-width: 183px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            padding: 10px;
            background: #ffffff;
            // box-shadow: 0px 0px 20px 5px rgba(151, 151, 151, 0.05);
            border-radius: 10px;

            img {
                width: 50px;
                height: 50px;
            }

            span {
                font-size: $fs-normal;
                font-weight: $fw-bold;
            }
        }
    }
}

.weather-detail {
    display: flex;
    align-items: center;
    gap: 15px;

    mat-icon.mat-icon {
        min-width: 24px;
    }

    .capacity,
    .desc {
        white-space: nowrap;
        flex: auto;
    }

    .capacity {
        text-align: right;
        font-weight: $fw-bold;
    }
}

button.btn-location {
    border: 1px solid white;
    min-width: 170px;
    height: 35px;
    text-align: center;

    mat-icon.mat-icon {
        min-width: 24px;
    }
}

/** Small Screen Breakpoint **/

@include media-breakpoint-down(sm) {
    .weather-board {
        .inner-board {
            top: 0;
            right: 0;
        }

        .vert-divider {
            height: auto;
        }

        .content-left {
            .weather {
                gap: 6px;
                img {
                    width: 90px;
                    height: 90px;
                }

                div {
                    text-align: center;
                }

                .weather-degree {
                    font-size: 30px;
                }
            }
        }

        .content-right {
            grid-template-columns: auto;
        }
    }

    button.btn-location {
        border: 1px solid white;
        min-width: 134px;
        height: 35px;
        text-align: center;
    }

    .weather-detail {
        // display: flex;
        // align-items: center;
        // gap: 15px;

        // mat-icon.mat-icon {
        //     min-width: 24px;
        // }

        .capacity,
        .desc {
            white-space: normal;
            flex: auto;
        }

        // .capacity {
        //     text-align: right;
        //     font-weight: $fw-bold;
        // }
    }
}
