@use "abstracts/variables" as *;
@use "base/font" as *;

.sugguestion {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .wrap-flex {
    display: flex;
    justify-content: space-between;
    height: 54px;
    gap: 5px;

    &.sug__title {
      height: 29px;
    }

    &.sug__content {
      align-items: center;
    }

    .shop-subcribe,
    .user-subscribed {
      background: white;
    }
  }
}

.image-wrapper {
  &.one-item {
    .bg-color {
      position: absolute;
      background-size: cover;
      // core code
      filter: blur(50px);
      transform: scale(3);
      height: 100%;
      width: 100%;
      top: 0;
      // &::before {
      //   filter: blur(50px);
      // }
    }

    .image-container.app-picture app-picture picture img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      position: absolute;
    }

    .image-container .image {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      position: absolute;
      background-size: contain !important;
    }
  }
}

.img-editor-container {
  display: flex;
  flex-direction: column;

  .image-section {
    min-height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .bg-color {
      position: absolute;
      background-size: cover;
      filter: blur(50px);
      transform: scale(3);
      height: 100%;
      width: 100%;
      top: 0;
    }

    app-image-cropper {
      max-height: 80vh;
    }

    mat-slider {
      width: 300px;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .navigation {
      display: flex;
      flex-direction: column;
      gap: 8px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          padding: 8px;
          gap: 8px;
          color: $text-color-gray;

          &:hover {
            // color: $color-secondary !important;
            background: rgba(0, 54, 64, 0.05);
            cursor: pointer;
            border-radius: 10px;
          }

          &.active {
            color: $color-secondary !important;
            background: rgba(0, 54, 64, 0.05);
            border-radius: 10px;

            mat-icon.mat-icon {
              path {
                fill: $color-secondary;
              }

              &.svg-path {
                &.svg-stroke {
                  path {
                    fill: none;
                    stroke: $color-secondary;
                  }
                }
              }
            }

            // .title {
            //   color: $color-secondary;
            // }
          }
        }
      }
    }

    .footer {
      display: flex;
      gap: 12px;
    }
  }
}

.comment-content {
  a {
    &.mention {
      text-decoration: none;
      font-weight: 500;
      color: rgba($color: #000000, $alpha: 0.9);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
