@use "abstracts/variables" as *;
@use "mixin/breakpoint" as *;

.report-content {
    .title-header {
        display: flex;
        align-items: center;
        // margin-bottom: 12px;
    }

    .post-card-v2 {
        background-color: transparent !important;
    }

    .border-right {
        border-right: 1px solid rgba(74, 98, 104, 0.1);
    }
}

.reply-report {
    .mat-step-icon {
        height: 40px;
        width: 40px;
        .mat-icon {
            --icon-size: 24px;
            font-size: var(--icon-size);
            height: var(--icon-size);
            width: var(--icon-size);
        }
    }

    .step-body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .image-box {
            width: 90px;
            height: 64px;
            border-radius: 8px;
            &:hover {
                cursor: pointer;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }
    }

    .mat-vertical-stepper-content {
        visibility: visible !important;
        overflow: unset !important;
        outline: 0;
        display: flex;
        min-height: 36px;
        height: auto !important;
    }

    .mat-vertical-content-container {
        padding-top: 0px !important;
    }

    .mat-vertical-content {
        padding: 0 24px 20px 34px;
    }

    .mat-step-icon-content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        .thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        background-color: transparent;
    }

    .mat-stepper-vertical-line::before {
        border-left-color: rgba(74, 98, 104, 0.1);
    }

    .mat-vertical-stepper-header {
        display: flex;
        align-items: center;
        height: 24px;

        .sub-title {
            display: flex;
            align-items: center;
            gap: 8px;

            .mat-divider.mat-divider-vertical {
                height: 14px;
            }
        }
    }

    // .mat-step-header .mat-step-icon {
    //     background-color: rgba(0, 0, 0, 0.54);
    //     color: white;
    // }

    .mat-vertical-content-container {
        margin-left: 20px;
        padding-top: 14px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
        padding: 18px 0px;
    }

    .mat-step-header .mat-step-icon,
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: #f4f7ff;
        color: white;
        border: 2px solid #ffffff;
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled="false"] {
        background-color: transparent;
        cursor: default;
    }
}

/** Medium Screen **/
@include media-breakpoint-down(md) {
    .report-content {
        .border-right {
            border-right: none;
        }
    }

    .shop-detail {
        &.report-content {
            .top .content-container .content img.profile-img {
                cursor: pointer;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                position: absolute;
                top: -60px;
                border: 5px solid #ffffff;
                object-fit: cover;
            }
        }
    }
}
