@use "../base/colors";
@use "mixin/breakpoint" as *;

$image-width: 400px;
$image-height: 270px;

.card-2 {
    border-radius: 10px;
    background: #fff;
}

.buy-listing-detail {
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 100%;

    .action-top-wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .outside-wrap {
        .containers {
            width: 60%;
            padding-right: 6%;
            overflow-y: auto;
            height: 100%;

            .mat-icon {
                min-width: 24px;
                min-height: 24px;
            }

            .label {
                min-width: fit-content;
            }

            .content-dialog {
                table {
                    border-spacing: 0 6px;

                    tr {
                        text-align: left;

                        th {
                            width: max-content;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .content-left {
                    &:has(img.default-img-product) {
                        margin-right: 0 !important;
                    }

                    .image {
                        width: $image-width;
                        height: $image-height;
                        // @include media-breakpoint-down(md) {
                        //     width: 100%;
                        // }

                        .slider {
                            width: $image-width;
                            height: $image-height;
                            // @include media-breakpoint-down(md) {
                            //     width: 100%;
                            // }

                            .default-img-product {
                                width: $image-width;
                                height: $image-height;
                                @include media-breakpoint-down(md) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .container-side {
            display: flex;
            flex-wrap: wrap;

            .app-picture-product-detail div app-picture picture img {
                width: $image-width;
                height: $image-height;
            }

            .right-side {
                width: calc(100% - 400px - 16px);
                min-width: 250px;

                @media screen and (max-width: "1046px") {
                    width: 100%;
                }
            }
        }
    }

    .shop-owner-card {
        max-width: 450px;
        display: flex;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.03);
        cursor: pointer;

        .shop-owner-img {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
        }
    }

    &.report-content {
        --img-width: 300px;
        --img-height: 200px;
        @include media-breakpoint-down(sm) {
            --img-width: 100%;
        }

        .outside-wrap {
            .container-side {
                display: flex;
                flex-wrap: nowrap;
                gap: 12px;
                .app-picture-product-detail div app-picture picture img {
                    width: var(--img-width);
                    height: var(--img-height);
                }
            }

            .containers .content-dialog .content-left .image {
                width: var(--img-width);
                height: var(--img-height);
                .slider {
                    width: var(--img-width);
                    height: var(--img-height);
                }
            }
        }
    }
}

.custom-radio-group {
    .mat-radio-button {
        display: block;
        height: 52px;

        &.mat-radio-checked,
        &:hover {
            .mat-radio-label {
                border: 1px solid colors.$dark-blue;
            }
        }

        .mat-radio-label {
            height: 100%;
            flex-direction: row-reverse;
            place-content: center space-between;
            border: 1px solid rgba($color: colors.$dark-blue, $alpha: 0.1);
            border-radius: 12px;
            padding: 8px 12px;
            transition: 0.2s all ease;

            .mat-radio-label-content {
                display: flex;
                align-items: center;
                padding: 0;

                .mat-icon {
                    width: 34px;
                    height: 34px;
                    margin-right: 12px;
                    font-size: 34px;
                }
            }
        }
    }
}

/** Medium Breakpoint **/
@include media-breakpoint-down(md) {
    .buy-listing-detail .outside-wrap .container-side {
        flex-direction: column;
    }

    .buy-listing-detail .outside-wrap .containers .content-dialog .content-left .image {
        width: 100%;
        max-height: 210px;
    }

    .buy-listing-detail .outside-wrap .containers .content-dialog .content-left .image .slider {
        width: 100%;
        max-height: 210px;
    }

    .buy-listing-detail .outside-wrap .container-side .app-picture-product-detail div app-picture picture img {
        /* width: 400px; */
        width: 100%;
        max-height: 210px;
    }

    .buy-listing-detail .outside-wrap .containers .content-dialog .content-left .image {
        width: 100%;
        max-height: 210px;
    }

    .outside-wrap .content-right .message {
        padding-right: 0px !important;
    }
}
