@use 'abstracts/variables' as *;
@use 'base/font' as *;

a.general-link {
  font-size: $fs-normal;
  color: $color-secondary;
  text-decoration: underline;
  cursor: pointer;

  &.link-sm {
      font-size: $fs-small;
  }

  &.link-lg {
    font-size: $fs-regular;
  }

  &.primary {
    color: $color-primary;
  }
}
