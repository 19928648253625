@use "sass:math";

$space-width: 20px;
.flex-spacer {
    flex: 1 1 auto !important;
}

.flex-box-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: math.div(-$space-width, 2);
    margin-right: math.div(-$space-width, 2);
    >* {
        flex: 12 1 300px;
        margin: math.div($space-width, 2);
        min-width: 250px;
    }
    .col-1 {
        flex: 12 1 calc(100% - #{$space-width});
    }
    .col-2 {
        flex: 6 1 calc(50% - #{$space-width});
    }
    .col-3 {
        flex: 4 1 calc(33.3333% - #{$space-width});
    }
    .col-4 {
        flex: 3 1 calc(25% - #{$space-width});
    }
    .col-invert-4 {
        flex: 9 1 calc(75% - #{$space-width});
    }
    .col-invert-3 {
        flex: 8 1 calc(66.6666% - #{$space-width});
    }
    .col-input {
        flex: 1 1 250px;
    }
    .profile-edit {
        flex: 4 1 200px;
    }
    
    .col-no-grow{
        flex: 0 1 calc(100% - #{$space-width});
        min-width: 150px;
    }

    @media (min-width: 540px) {
        .col-no-grow{
            flex: 0 1 calc(50% - #{$space-width});
            min-width: 150px;
        }
    }

    @media (min-width: 720px) {
        .col-no-grow{
            flex: 0 1 calc(33.3333% - #{$space-width});
            min-width: 150px;
        }
    }

    @media (min-width: 1080px) {
        .col-no-grow{
            flex: 0 1 calc(25% - #{$space-width});
            min-width: 150px;
        }
    }
}

.flex {
    display: flex;
    a {
        margin-left: 2px;
    }
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap;

    .flex-1 {
        flex: 1;
    }
}

.flex-item-center{
    display: flex;
    align-items: center;
}