@use "base/font" as *;
@use "abstracts/variables" as *;

.prof-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  .info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .info-title {
      font-size: $fs-normal;
      font-weight: $fw-bold;
    }
  }

  &.prof-box-sm {
    width: 35px;
    height: 35px;
  }
  &.prof-box-md {
    width: 40px;
    height: 40px;
  }
  &.prof-box-lg {
    width: 60px;
    height: 60px;
  }
  .prof-img {
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


}